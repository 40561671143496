define("fitbase/components/set-frequency/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    frequency: 0,
    isWeeklySvg: Ember.computed('frequency', function () {
      return this.radioSvg(this.frequency, 7);
    }),
    isMonthlySvg: Ember.computed('frequency', function () {
      return this.radioSvg(this.frequency, 28);
    }),
    isBiMonthlySvg: Ember.computed('frequency', function () {
      return this.radioSvg(this.frequency, 14);
    }),
    radioSvg: function radioSvg(frequency, n) {
      return frequency === n ? 'assets/images/icon-radio-active' : 'assets/images/icon-radio-inactive';
    }
  });

  _exports.default = _default;
});