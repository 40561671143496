define("fitbase/mixins/uses-modals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.activateModal = activateModal;
  _exports.deactivateModal = deactivateModal;

  function activateModal() {
    window.document.querySelector('html').classList.add('modal-active');
  }

  function deactivateModal() {
    window.document.querySelector('html').classList.remove('modal-active');
  }
});