define("fitbase/components/checkin-field-note/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y6mn86VQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"checkin-input__label\"],[8],[1,[22,\"label\"],false],[9],[7,\"div\",true],[10,\"class\",\"checkin-input__help\"],[8],[1,[22,\"help\"],false],[9],[7,\"div\",true],[10,\"class\",\"checkin-input__field-wrap\"],[8],[1,[28,\"textarea-one-way\",null,[[\"value\",\"update\",\"spellcheck\",\"disabled\",\"required\",\"class\"],[[24,[\"value\"]],[24,[\"update\"]],\"false\",[24,[\"disabled\"]],[24,[\"disabled\"]],\"checkin-input__note checkin-input__elem\"]]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/checkin-field-note/template.hbs"
    }
  });

  _exports.default = _default;
});