define("fitbase/utils/user-validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.editPassword = _exports.password = _exports.email = _exports.fullname = void 0;
  var fullname = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    ignoreBlank: true,
    message: "Please enter a name"
  })];
  _exports.fullname = fullname;
  var email = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    ignoreBlank: true,
    message: "Please enter an email"
  }), (0, _emberCpValidations.validator)('format', {
    type: 'email',
    allowBlank: true
  })];
  _exports.email = email;
  var password = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    ignoreBlank: true,
    message: "Please enter a password"
  }), (0, _emberCpValidations.validator)('length', {
    min: 10,
    max: 24,
    allowBlank: true,
    message: "At least 10 characters"
  })];
  _exports.password = password;
  var editPassword = [(0, _emberCpValidations.validator)('length', {
    min: 10,
    max: 24,
    allowBlank: true,
    message: "At least 10 characters"
  })];
  _exports.editPassword = editPassword;
  var _default = {
    email: email,
    password: password,
    editPassword: editPassword
  };
  _exports.default = _default;
});