define("fitbase/application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    currentUser: Ember.computed.alias('session.currentUser'),
    flashMessages: Ember.inject.service()
  });

  _exports.default = _default;
});