define("fitbase/components/checkin-field-image/component", ["exports", "ember-gestures/mixins/recognizers", "fitbase/config/environment", "fetch"], function (_exports, _recognizers, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_recognizers.default, {
    imgixQuery: "?fit=crop&w=340&h=340",
    imageClass: Ember.computed('image', function () {
      if (Ember.get(this, 'image')) {
        return 'checkin-upload__drop-zone--has-image';
      }

      return false;
    }),
    uploadingClass: Ember.computed('isUploading', function () {
      if (Ember.get(this, 'isUploading')) {
        return 'checkin-upload__drop-zone--uploading';
      }

      return '';
    }),
    uploadFolder: Ember.computed('checkinId', function () {
      return "checkin-images/".concat(Ember.get(this, 'checkinId'));
    }),
    session: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:session');
    }),
    image: Ember.computed('value', 'isUploading', function () {
      var uploadedImg = Ember.get(this, 'value');
      var isUploading = Ember.get(this, 'isUploading');

      if (uploadedImg && !isUploading) {
        return uploadedImg;
      } else {
        return false;
      }
    }),
    signingUrl: "".concat(_environment.default.DS.host, "/").concat(_environment.default.DS.namespace, "/upload-signatures"),
    getSignature: function getSignature(params) {
      var response;
      return regeneratorRuntime.async(function getSignature$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return regeneratorRuntime.awrap((0, _fetch.default)(this.signingUrl, {
                method: 'POST',
                cache: 'no-cache',
                credentials: 'include',
                headers: {
                  'Authorization': "Bearer ".concat(Ember.get(this, 'session.data.authenticated.access_token')),
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
              }));

            case 2:
              response = _context.sent;
              _context.next = 5;
              return regeneratorRuntime.awrap(response.json());

            case 5:
              return _context.abrupt("return", _context.sent);

            case 6:
            case "end":
              return _context.stop();
          }
        }
      }, null, this);
    },
    actions: {
      upload: function upload(file) {
        var _this = this;

        this.set('isUploading', true);
        var signParams = {
          folder: Ember.get(this, 'uploadFolder'),
          prefix: "".concat(Ember.get(this, 'fieldId'), "-"),
          type: file.type,
          name: file.name
        };
        Promise.resolve(this.getSignature(signParams)).then(function (response) {
          return file.upload(response.endpoint, {
            data: {
              "AWSAccessKeyId": response.AWSAccessKeyId,
              "policy": response.policy,
              "signature": response.signature,
              "acl": response.acl,
              "key": response.key,
              "success_action_status": response.success_action_status
            }
          });
        }).then(function (response) {
          var dom = new DOMParser();
          var xml = dom.parseFromString(response.body, 'text/xml');
          var url = xml.querySelector('Location').textContent;

          _this.send('setUploadedUrl', url);
        });
      },
      didChange: function didChange() {
        this.set('isUploading', true);
        this.set('progress', 0);
      },
      onProgress: function onProgress(e) {
        this.set('progress', e.percent);
      },
      onError: function onError()
      /*jqXHR, textStatus, errorThrown*/
      {
        alert('Unable to complete the upload. Please contact support@fitbasepro.com.');
        this.set('isUploading', false);
      },
      setUploadedUrl: function setUploadedUrl(url) {
        var _this2 = this;

        var urlPath = url.replace(/^.*amazonaws\.com/, '');
        this.get('update')(urlPath);
        this.get('save')().then(function () {
          _this2.set('isUploading', false);
        });
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('update')) {
        throw new Error("You must provide an `update` action to `{{".concat(this.templateName, "}}`."));
      }

      this.get('update')(this.get('value'));
    }
  });

  _exports.default = _default;
});