define("fitbase/checkin-form/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);

      var order = Ember.get(snapshot, 'fieldsOrder');

      if (Ember.isEmpty(order)) {
        return json;
      }

      json.attributes.order = order;
      return json;
    }
  });

  _exports.default = _default;
});