define("fitbase/session/service", ["exports", "ember-data", "ember-simple-auth/services/session", "fitbase/config/environment"], function (_exports, _emberData, _session, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _session.default.extend({
    store: Ember.inject.service(),
    intercom: Ember.inject.service(),
    claims: Ember.computed('token', function () {
      var token = Ember.get(this, 'token');

      var payload = this._parsePayload(token);

      if (payload) {
        return payload;
      }

      return null;
    }),
    subject: Ember.computed('claims', function () {
      var sub = this.get('claims.sub');

      if (sub) {
        return sub.toLowerCase().split(':');
      }

      return null;
    }),
    subjectType: Ember.computed('subject', function () {
      var subject = Ember.get(this, 'subject');

      if (subject) {
        return Ember.get(this, 'subject')[0];
      }

      return null;
    }),
    exp: Ember.computed('claims', function () {
      return this.get('claims.exp');
    }),
    token: Ember.computed.alias('data.authenticated.access_token'),
    currentUser: Ember.computed('isAuthenticated', function () {
      if (Ember.get(this, 'isAuthenticated')) {
        var promise = Ember.get(this, 'store').findRecord('user', 'current');
        return _emberData.default.PromiseObject.create({
          promise: promise
        });
      }

      return null;
    }),
    currentSubject: Ember.computed('subject', 'isAuthenticated', function () {
      if (Ember.get(this, 'isAuthenticated')) {
        var _this$get = this.get('subject'),
            _this$get2 = _slicedToArray(_this$get, 2),
            subjectType = _this$get2[0],
            id = _this$get2[1];

        var store = Ember.get(this, 'store');

        if (subjectType === 'user') {
          var promise = store.findRecord('user', 'current');
          return _emberData.default.PromiseObject.create({
            promise: promise
          });
        }

        if (subjectType === 'checkin') {
          var _promise = store.findRecord('checkin', id);

          return _emberData.default.PromiseObject.create({
            promise: _promise
          });
        }

        if (subjectType === 'client') {
          var _promise2 = store.findRecord('client', id);

          return _emberData.default.PromiseObject.create({
            promise: _promise2
          });
        }
      }

      return null;
    }),
    identify: function identify() {
      var _this = this;

      var sub = this.get('subject');

      var _sub = _slicedToArray(sub, 1),
          subjectType = _sub[0];

      var env_prefix = _environment.default.deployTarget !== "production" ? _environment.default.deployTarget + '_' : '';
      var identifier = "".concat(env_prefix).concat(sub.join('_'));
      return this.get('currentSubject').then(function (subject) {
        var attrs = {
          identifier: identifier,
          name: null,
          email: null,
          env: _environment.default.deployTarget
        };

        if (subjectType === "user") {
          attrs.name = Ember.get(subject, 'email');
          attrs.email = Ember.get(subject, 'email');
        }

        if (subjectType === "checkin") {
          attrs.name = Ember.get(subject, 'client.name');
          attrs.email = Ember.get(subject, 'client.email');
        }

        if (subjectType === "client") {
          attrs.name = Ember.get(subject, 'name');
          attrs.email = Ember.get(subject, 'email');
        }

        return _this._sendIdentities(attrs);
      });
    },
    unidentify: function unidentify() {
      if (_environment.default.environment === "test") {
        return;
      }

      this._resetIdentities();
    },
    _sendIdentities: function _sendIdentities(attrs) {
      var fullstory = window.FS || {
        identify: function identify() {
          console['log']('Reset fullstory debug');
        }
      };
      var intercom = this.get('intercom');
      intercom.set('user.name', attrs.name);
      intercom.set('user.email', attrs.email);
      fullstory.identify(attrs.identifier, {
        displayName: attrs.name,
        email: attrs.email,
        env_str: attrs.env
      });
    },
    _resetIdentities: function _resetIdentities() {
      var fullstory = window.FS || {
        identify: function identify() {
          console.log('Reset fullstory debug');
        }
      };
      fullstory.identify(false);
      this.get('intercom').stop();
    },
    _parsePayload: function _parsePayload(token) {
      try {
        var parts = token.split('.');
        var payload = parts[1];

        if (payload) {
          return JSON.parse(atob(payload));
        } else {
          return false;
        }
      } catch (e) {
        console.warn('token', token, 'failed to be valid base64 encoding.');
        return false;
      }
    }
  });

  _exports.default = _default;
});