define("fitbase/components/input-auth/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kvvPt0Ia",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"input-one-way\",null,[[\"value\",\"update\",\"placeholder\",\"type\",\"id\",\"class\",\"classNames\",\"focusIn\",\"focusOut\",\"enter\"],[[24,[\"value\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"value\"]]],null]],null],[24,[\"placeholder\"]],[24,[\"type\"]],[24,[\"inputName\"]],[24,[\"inputClass\"]],\"input__field input__field--text input__field--auth\",[28,\"action\",[[23,0,[]],\"onFocusIn\"],null],[28,\"action\",[[23,0,[]],\"onFocusOut\"],null],[24,[\"enter\"]]]]],false],[7,\"label\",true],[11,\"data-error\",[22,\"errorMessages\"]],[11,\"for\",[22,\"inputName\"]],[10,\"class\",\"input__label input__label--auth\"],[8],[1,[22,\"label\"],false],[14,1],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/input-auth/template.hbs"
    }
  });

  _exports.default = _default;
});