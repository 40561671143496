define("fitbase/components/client-head/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q6kEGbrN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"data-initials\",[28,\"client-initials\",[[24,[\"client\",\"name\"]]],null]],[10,\"class\",\"client__avatar\"],[8],[7,\"img\",true],[11,\"src\",[24,[\"client\",\"avatarUrl\"]]],[8],[9],[9],[7,\"h1\",true],[10,\"class\",\"client__name-display\"],[8],[4,\"link-to\",null,[[\"route\",\"model\"],[\"coach.clients.client\",[24,[\"client\",\"id\"]]]],{\"statements\":[[1,[24,[\"client\",\"name\"]],false]],\"parameters\":[]},null],[7,\"div\",true],[11,\"class\",[29,[\"client__state \",[22,\"stateClass\"]]]],[8],[1,[22,\"status\"],false],[9],[9],[7,\"div\",true],[10,\"class\",\"client-actions\"],[8],[7,\"div\",false],[12,\"class\",\"client-actions__icon actions-icon\"],[3,\"action\",[[23,0,[]],\"toggleActions\"],[[\"bubbles\",\"on\"],[false,[24,[\"tap\"]]]]],[8],[0,\"•••\"],[9],[4,\"if\",[[24,[\"isShowingActions\"]]],null,{\"statements\":[[7,\"div\",false],[12,\"class\",\"client-actions__list\"],[3,\"action\",[[23,0,[]],\"didClickAction\"],[[\"bubbles\",\"on\"],[false,[24,[\"tap\"]]]]],[8],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"client-action\",\"coach.clients.client.edit\",[24,[\"client\",\"id\"]]]],{\"statements\":[[0,\"Edit contact\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"client\",\"checkinForm\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"client-action\",\"coach.clients.client.edit.checkin-form\",[24,[\"client\",\"id\"]]]],{\"statements\":[[0,\"Edit check-in settings\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[24,[\"client\",\"checkinLatest\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"client-action\",\"coach.clients.client.checkins\",[24,[\"client\",\"id\"]]]],{\"statements\":[[0,\"View check-in history\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[9]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/client-head/template.hbs"
    }
  });

  _exports.default = _default;
});