define("fitbase/checkin/authenticator", ["exports", "fitbase/application/authenticator"], function (_exports, _authenticator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var assign = Ember.assign || Ember.merge;

  var _default = _authenticator.default.extend({
    /**
      The grant_type implemented by the server to authenticate against
       @property grantType
      @type String
      @default 'checkin'
      @public
    */
    grantType: 'checkin',
    authenticate: function authenticate(identification, password) {
      var _this = this;

      var scope = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var data = {
          'grant_type': _this.get('grantType'),
          username: identification,
          password: password
        };

        var serverTokenEndpoint = _this.get('serverTokenEndpoint');

        var useResponse = _this.get('rejectWithResponse');

        var scopesString = Ember.makeArray(scope).join(' ');

        if (!Ember.isEmpty(scopesString)) {
          data.scope = scopesString;
        }

        _this.makeRequest(serverTokenEndpoint, data, headers).then(function (response) {
          Ember.run(function () {
            if (!_this._validate(response)) {
              reject('access_token is missing in server response');
            }

            var expiresAt = _this._absolutizeExpirationTime(response['expires_in']);

            if (!Ember.isEmpty(expiresAt)) {
              response = assign(response, {
                'expires_at': expiresAt
              });
            }

            resolve(response);
          });
        }, function (response) {
          Ember.run(null, reject, useResponse ? response : response.responseJSON);
        });
      });
    },
    invalidate: function invalidate() {
      return new Ember.RSVP.Promise(function (resolve) {
        return resolve();
      });
    },
    _validate: function _validate(data) {
      return !Ember.isEmpty(data['access_token']);
    }
  });

  _exports.default = _default;
});