define("fitbase/components/clients-nav/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this.clientSorts = ["name"];

      this._super.apply(this, arguments);
    },
    session: Ember.inject.service(),
    tagName: 'nav',
    classNames: ['clients-nav', 'column', 'column--left'],
    classNameBindings: ['isIndex', 'isFiltering', 'filteringBy'],
    filteringBy: Ember.computed('session.filteringClientsBy', {
      get: function get() {
        return Ember.getWithDefault(this, 'session.filteringClientsBy', "");
      },
      set: function set() {
        var _arguments = Array.prototype.slice.call(arguments),
            value = _arguments[1];

        this.set('session.filteringClientsBy', value);
        return value;
      }
    }),
    query: null,
    queriedBy: Ember.computed('query', 'allClients.length', function () {
      if (this.query) {
        return "matching ".concat(this.query, ".");
      } else if (this.allClients.length === 0) {
        return "yet. They'll show here after you add some.";
      } else {
        return ".";
      }
    }),
    matchQuery: function matchQuery(clients) {
      var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

      if (!query) {
        return clients;
      }

      query = query.toLowerCase();
      var filtered = clients.filter(function (client) {
        var nameMatch = client.get('name').toLowerCase().indexOf(query) >= 0;
        var emailMatch = client.get('email').toLowerCase().indexOf(query) >= 0;
        return nameMatch || emailMatch;
      });
      return filtered;
    },
    isFiltering: Ember.computed.notEmpty('filteringBy'),
    clients: Ember.computed('filteringBy', 'query', function () {
      var filter = this.get('filteringBy');
      var prop = '';

      switch (filter) {
        case "unreplied":
          prop = 'unrepliedClients';
          break;

        case "late":
          prop = 'lateClients';
          break;

        case "ended":
          prop = 'endedClients';
          break;

        default:
          prop = 'activeClientsSorted';
          break;
      }

      var list = this.get(prop);
      return this.matchQuery(list, this.query);
    }),
    activeClientsSorted: Ember.computed.sort('activeClients', 'clientSorts'),
    activeClients: Ember.computed.filter('allClients.@each.{state,isNew}', function (client) {
      return client.get('state') !== "archived" && client.get('isNew') == false;
    }),
    activeClientsCount: Ember.computed.alias('activeClients.length'),
    newClients: Ember.computed.filterBy('activeClientsSorted', 'state', "new"),
    newClientsCount: Ember.computed.alias('newClients.length'),
    endedClients: Ember.computed.filterBy('activeClientsSorted', 'isEnded'),
    endedClientsCount: Ember.computed.alias('endedClients.length'),
    unrepliedClients: Ember.computed.filter('activeClientsSorted.@each.{state,hasUncoachedMessage}', function (client) {
      return client.get('state') == 'checked_in' || client.get('hasUncoachedMessage');
    }),
    unrepliedClientsCount: Ember.computed.alias('unrepliedClients.length'),
    checkedInClientsCount: Ember.computed('activeClientsSorted.@each.{state}', function () {
      return this.get('activeClientsSorted').filterBy('state', 'checked_in').get('length');
    }),
    lateClients: Ember.computed.filter('activeClientsSorted.@each.{state,stateUpdatedAt}', function (client) {
      var isOpen = client.get('state') == 'open_checkin';
      var updatedAt = client.get('stateUpdatedAt');
      var isLateAt = (0, _moment.default)(updatedAt).add(1, 'days');
      return (0, _moment.default)().isAfter(isLateAt) && isOpen;
    }),
    lateClientsCount: Ember.computed.alias('lateClients.length'),
    archivedClients: Ember.computed.filterBy('allClients', 'state', "archived"),
    archivedClientsCount: Ember.computed.alias('archivedClients.length'),
    archivedClientsSorted: Ember.computed.sort('archivedClients', 'clientSorts'),
    hidingArchived: true,
    actions: {
      toggleArchivedClients: function toggleArchivedClients() {
        this.toggleProperty('hidingArchived');
      },
      filterClients: function filterClients(filterBy) {
        var isFilteringBy = this.get('filteringBy');

        if (filterBy == isFilteringBy) {
          filterBy = "";
        }

        this.set("filteringBy", filterBy);
      }
    }
  });

  _exports.default = _default;
});