define("fitbase/components/focus-first-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.focusableSelectors = ['[type="text"]', '[type="email"]', '[type="password"]', 'textarea'];
    },
    didInsertElement: function didInsertElement() {
      var selectors = this.get('focusableSelectors').join(',');
      this.element.querySelectorAll(selectors)[0].focus();
    }
  });

  _exports.default = _default;
});