define("fitbase/components/checkin-field-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fR2/SIYx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"checkin-input__label\"],[8],[1,[22,\"label\"],false],[9],[7,\"div\",true],[10,\"class\",\"checkin-input__help\"],[8],[1,[22,\"help\"],false],[9],[7,\"div\",true],[10,\"class\",\"checkin-input__field-wrap\"],[8],[7,\"label\",true],[11,\"for\",[22,\"name\"]],[10,\"class\",\"checkin-input__unit\"],[8],[1,[22,\"unit\"],false],[9],[1,[28,\"input-one-way\",null,[[\"type\",\"id\",\"placeholder\",\"value\",\"enter\",\"update\",\"disabled\",\"class\"],[\"text\",[24,[\"name\"]],[24,[\"placeholder\"]],[24,[\"value\"]],[24,[\"enter\"]],[24,[\"update\"]],[24,[\"disabled\"]],\"checkin-input__field checkin-input__elem checkin-input__field--text\"]]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/checkin-field-text/template.hbs"
    }
  });

  _exports.default = _default;
});