define("fitbase/components/input-control/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['displayError:data-error'],
    classNames: ['input', 'input--layout'],
    classNameBindings: ['isInvalid:input--invalid'],
    error: null,
    displayError: Ember.computed('error', 'showValidations', 'validationError', function () {
      if (this.get('showValidations')) {
        return this.get('validationError');
      } else {
        return this.get('error');
      }
    }),
    isInvalid: Ember.computed('displayError', function () {
      return !!this.get('displayError');
    })
  });

  _exports.default = _default;
});