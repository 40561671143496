define("fitbase/checkin/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    checkin: Ember.computed.alias('model'),
    client: Ember.computed.alias('model.checkinForm.client'),
    name: Ember.computed.alias('client.name'),
    checkinValues: Ember.computed.alias('checkin.checkinValues'),
    checkinForm: Ember.computed.alias('checkin.checkinForm'),
    checkinFields: Ember.computed.alias('checkinForm.checkinFields'),
    fieldsCount: Ember.computed('checkinFields.[]', function () {
      return this.get('checkinFields.length');
    }),
    subject: Ember.computed.alias('session.subjectType'),
    percentFilled: Ember.computed('checkinFields', 'checkinValues.@each.value', function () {
      var saved = this.get('checkinValues').filter(function (cv) {
        return !Ember.isEmpty(cv.get('value'));
      }).length;
      var count = this.get('fieldsCount');
      var percent = saved / count * 100;
      return percent;
    })
  });

  _exports.default = _default;
});