define("fitbase/components/checkins-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mLVY8pMW",
    "block": "{\"symbols\":[\"checkin\",\"field\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"checkins-list__toggle-incomplete \",[28,\"if\",[[24,[\"isShowingIncomplete\"]],\"is-visible\",\"is-hidden\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"toggleShowingIncomplete\"]],[8],[1,[28,\"inline-svg\",[\"assets/images/visibility-toggle\"],null],false],[9],[7,\"div\",true],[10,\"class\",\"checkins-head\"],[8],[7,\"div\",true],[11,\"style\",[22,\"colwidth\"]],[10,\"class\",\"checkins-head__label\"],[8],[0,\"Date due\"],[9],[4,\"each\",[[24,[\"numberFields\"]]],null,{\"statements\":[[7,\"div\",true],[11,\"style\",[22,\"colwidth\"]],[11,\"title\",[23,2,[\"text\"]]],[10,\"class\",\"checkins-head__label\"],[8],[1,[23,2,[\"text\"]],false],[9]],\"parameters\":[2]},null],[9],[4,\"each\",[[24,[\"checkins\"]]],null,{\"statements\":[[1,[28,\"checkins-li\",null,[[\"colspan\",\"colwidth\",\"checkin\",\"checkinFieldValues\"],[[24,[\"colspan\"]],[24,[\"colwidth\"]],[23,1,[]],[23,1,[\"checkinFieldValues\"]]]]],false]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/checkins-list/template.hbs"
    }
  });

  _exports.default = _default;
});