define("fitbase/components/checkin-intro/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ck1dMDcH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"columns\"],[8],[7,\"div\",true],[10,\"class\",\"column column--single\"],[8],[7,\"div\",true],[10,\"class\",\"checkin-intro__title\"],[8],[1,[22,\"title\"],false],[9],[7,\"div\",true],[10,\"class\",\"checkin-intro__instruction\"],[8],[1,[22,\"instruction\"],false],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/checkin-intro/template.hbs"
    }
  });

  _exports.default = _default;
});