define("fitbase/components/input-date/component", ["exports", "ember-pikaday/components/pikaday-input"], function (_exports, _pikadayInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pikadayInput.default.extend({
    touchStart: function touchStart() {
      this.set('touchMoved', false);
    },
    touchMove: function touchMove() {
      this.set('touchMoved', true);
    },
    touchEnd: function touchEnd(e) {
      if (this.get('touchMoved')) {
        e.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});