define("fitbase/components/checkin-latest-number/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wNa1ikBj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"current-stat__label\"],[8],[1,[24,[\"fieldValue\",\"label\"]],false],[9],[7,\"div\",true],[10,\"class\",\"current-stat__value\"],[8],[1,[24,[\"fieldValue\",\"value\"]],false],[9],[7,\"div\",true],[10,\"class\",\"current-stat__value-unit\"],[8],[1,[24,[\"fieldValue\",\"unit\"]],false],[9],[7,\"div\",true],[11,\"title\",[22,\"deltaTitle\"]],[10,\"class\",\"current-stat__delta\"],[8],[1,[22,\"deltaText\"],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/checkin-latest-number/template.hbs"
    }
  });

  _exports.default = _default;
});