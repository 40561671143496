define("fitbase/components/config-checkin-time/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f9y2e6T9",
    "block": "{\"symbols\":[\"hour\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"checkin-time__title\"],[8],[0,\"Hour\"],[9],[7,\"div\",true],[10,\"class\",\"checkin-time__hours\"],[8],[4,\"each\",[[24,[\"hours\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"nextAtHour\"]],[23,1,[]]],null]],null,{\"statements\":[[7,\"button\",false],[12,\"class\",\"checkin-time__hour is-active\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"selectHour\",[23,1,[]]]],[8],[1,[23,1,[]],false],[9]],\"parameters\":[]},{\"statements\":[[7,\"button\",false],[12,\"class\",\"checkin-time__hour\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"selectHour\",[23,1,[]]]],[8],[1,[23,1,[]],false],[9]],\"parameters\":[]}]],\"parameters\":[1]},null],[9],[7,\"div\",true],[10,\"class\",\"checkin-time__periods\"],[8],[7,\"button\",false],[12,\"class\",[29,[\"checkin-time__period \",[28,\"if\",[[24,[\"isAm\"]],\"is-active\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"selectPeriod\",\"am\"]],[8],[0,\"am\"],[9],[7,\"button\",false],[12,\"class\",[29,[\"checkin-time__period \",[28,\"if\",[[24,[\"isPm\"]],\"is-active\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"selectPeriod\",\"pm\"]],[8],[0,\"pm\"],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/config-checkin-time/template.hbs"
    }
  });

  _exports.default = _default;
});