define("fitbase/components/client-li/component", ["exports", "moment", "fitbase/helpers/moment-ref-date"], function (_exports, _moment, _momentRefDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['client-li'],
    classNameBindings: ['client.state', 'isLate', 'ended', 'uncoached'],
    uncoached: Ember.computed.alias('client.hasUncoachedMessage'),
    ended: Ember.computed.alias('client.isEnded'),
    isLate: Ember.computed('client.{state,state_updated_at}', function () {
      var isOpen = this.get('client.state') == 'open_checkin';
      var updatedAt = this.get('client.stateUpdatedAt');
      var isLateAt = (0, _moment.default)(updatedAt).add(1, 'days');
      return (0, _moment.default)().isAfter(isLateAt) && isOpen;
    }),
    checkinForm: Ember.computed.alias('client.checkinForm'),
    statusMessage: Ember.computed('client.{state,state_updated_at,isEnded}', function () {
      var state = this.get('client.state');
      var updatedAt = this.get('client.stateUpdatedAt');
      var now = (0, _moment.default)();
      var updatedRelative = (0, _moment.default)(updatedAt).from(now);

      if (this.get('client.isEnded')) {
        var endedRelative = (0, _moment.default)(this.get('client.icsDtend')).from(now);
        return "Ended ".concat(endedRelative);
      }

      switch (state) {
        case "open_checkin":
          return "Delivered ".concat(updatedRelative);

        case "new":
          return "New client needs setup";

        case "checked_in":
          return "Checked in ".concat(updatedRelative);

        case "replied":
          return "Sent reply ".concat(updatedRelative);

        case "enqueued":
          return 'Scheduled';

        case "paused":
          return 'Paused';

        default:
          return '';
      }
    }),
    nextAtMessage: Ember.computed('checkinForm', 'checkinForm.{isFulfilled,nextAt}', 'client.state', 'ended', function () {
      var clientState = Ember.get(this, 'client.state');
      var notFulfilled = !Ember.get(this, 'checkinForm.isFulfilled');

      if (clientState === "new" || notFulfilled) {
        return '';
      }

      var nextAt = Ember.get(this, 'checkinForm.nextAt');

      if (typeof nextAt == "undefined") {
        console.error('Undefined nextAt', Ember.get(this, 'checkinForm'));
      }

      if (nextAt && !this.ended) {
        var nextAtRelative = (0, _momentRefDate.relativeDate)(nextAt, true);
        return Ember.String.htmlSafe("Next: <b>".concat(nextAtRelative, "</b>"));
      } else {
        return "";
      }
    })
  });

  _exports.default = _default;
});