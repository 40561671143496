define("fitbase/lib/assert-closure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    var _console;

    (_console = console).error.apply(_console, ['Please pass a method to this property to override this default assertion'].concat(Array.prototype.slice.call(arguments)));
  }
});