define("fitbase/coach/clients/client/index/route", ["exports", "fitbase/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var clientId = this.paramsFor('coach.clients.client').id;

      if (clientId) {
        return Ember.get(this, 'store').peekRecord('client', clientId);
      }
    },
    afterModel: function afterModel() {
      this.routeModel().belongsTo('checkinLatest').reload();
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.setProperties({
        clients: this.modelFor('coach.clients')
      });
    },
    routeModel: function routeModel() {
      return this.modelFor(this.routeName);
    },
    findDraft: function findDraft() {
      var client, checkin, draft;
      return regeneratorRuntime.async(function findDraft$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              client = this.routeModel();
              _context.next = 3;
              return regeneratorRuntime.awrap(client.get('checkinLatest'));

            case 3:
              checkin = _context.sent;
              _context.next = 6;
              return regeneratorRuntime.awrap(checkin.get('checkinReplyDraft'));

            case 6:
              draft = _context.sent;
              return _context.abrupt("return", {
                client: client,
                checkin: checkin,
                draft: draft
              });

            case 8:
            case "end":
              return _context.stop();
          }
        }
      }, null, this);
    },
    actions: {
      createCheckinForm: function createCheckinForm() {
        var _this = this;

        var client = this.routeModel();

        if (!Ember.get(client, 'checkinForm.id')) {
          var checkinForm = this.store.createRecord('checkin-form', {
            client: client
          });
          client.set('checkinForm', checkinForm);
          checkinForm.save().then(function () {
            _this.transitionTo('coach.clients.client.edit.checkin-form');
          });
        }
      },
      updateReplyDraft: function updateReplyDraft(input) {
        var _ref, client, checkin, draft;

        return regeneratorRuntime.async(function updateReplyDraft$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return regeneratorRuntime.awrap(this.findDraft());

              case 2:
                _ref = _context2.sent;
                client = _ref.client;
                checkin = _ref.checkin;
                draft = _ref.draft;

                if (!(input && Ember.isEmpty(draft))) {
                  _context2.next = 10;
                  break;
                }

                _context2.next = 9;
                return regeneratorRuntime.awrap(this.store.createRecord('checkin-reply', {
                  checkin: checkin,
                  client: client,
                  sentAt: null,
                  input: input
                }));

              case 9:
                draft = _context2.sent;

              case 10:
                if (!Ember.isEmpty(draft)) {
                  draft.set('body', input);
                  Ember.run.debounce(draft, 'save', 1000);
                }

              case 11:
              case "end":
                return _context2.stop();
            }
          }
        }, null, this);
      },
      sendReply: function sendReply() {
        var _ref2, draft, client;

        return regeneratorRuntime.async(function sendReply$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.next = 2;
                return regeneratorRuntime.awrap(this.findDraft());

              case 2:
                _ref2 = _context3.sent;
                draft = _ref2.draft;
                client = _ref2.client;
                draft.set('sentAt', new Date());
                _context3.next = 8;
                return regeneratorRuntime.awrap(draft.save());

              case 8:
                client.reload();

              case 9:
              case "end":
                return _context3.stop();
            }
          }
        }, null, this);
      },
      updateCoachedAt: function updateCoachedAt(client) {
        return regeneratorRuntime.async(function updateCoachedAt$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                client.setProperties({
                  lastCoachedAt: new Date(),
                  hasUncoachedMessage: false
                });
                _context4.next = 3;
                return regeneratorRuntime.awrap(client.save());

              case 3:
              case "end":
                return _context4.stop();
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});