define("fitbase/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "fitbase/config/environment", "ember-data"], function (_exports, _applicationRouteMixin, _environment, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    routeAfterAuthentication: Ember.computed('session.subject', function () {
      var _EmberGet = Ember.get(this, 'session.subject'),
          _EmberGet2 = _slicedToArray(_EmberGet, 2),
          model = _EmberGet2[0],
          id = _EmberGet2[1];

      var start_path = Ember.get(this, 'session.data.start_path');

      switch (model) {
        case 'checkin':
          return "/checkin/".concat(id);

        case 'client':
          if (start_path) {
            return start_path;
          } else {
            return "/checkins";
          }

        default:
          return '/coach/clients';
      }
    }),
    sessionAuthenticated: function sessionAuthenticated() {
      if (_environment.default.environment !== "test") {
        Ember.get(this, 'session').identify();
      }

      return this._super.apply(this, arguments);
    },
    sessionInvalidated: function sessionInvalidated() {
      if (_environment.default.environment !== "test") {
        Ember.get(this, 'session').unidentify();
      }

      return this._super.apply(this, arguments);
    },
    actions: {
      logout: function logout() {
        Ember.get(this, 'session').invalidate();
      },
      error: function error(reason) {
        if (reason instanceof _emberData.default.AdapterError && reason.errors && reason.errors[0].status == 404) {
          this.transitionTo('/not-found');
        }
      }
    }
  });

  _exports.default = _default;
});