define("fitbase/components/checkins-list/component", ["exports", "fitbase/utils/field-units"], function (_exports, _fieldUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var unitFields = _fieldUnits.default.lookupList('inputType');

  var _default = Ember.Component.extend({
    isShowingIncomplete: false,
    classNames: 'checkins-list',
    classNameBindings: ['isShowingIncomplete:checkins-list--show-incomplete'],
    fieldsSorted: Ember.computed('fields.[]', function () {
      if (Ember.get(this, 'fields')) {
        return Ember.get(this, 'fields').sortBy('sequence', 'id');
      } else {
        return Ember.get(this, 'fields');
      }
    }),
    numberFields: Ember.computed.filter('fieldsSorted.[]', function (f) {
      return unitFields[Ember.get(f, 'unit')] === "number";
    }),
    hasNoteFields: Ember.computed('fields.[]', function () {
      if (Ember.get(this, 'fields')) {
        return Ember.get(this, 'fields').any(function (item) {
          return ["note", "text"].indexOf(unitFields[Ember.get(item, 'unit')]) >= 0;
        });
      } else {
        return false;
      }
    }),
    columnLimit: false,
    colspan: Ember.computed('columnLimit', 'numberfields.[]', function () {
      var limit = Ember.get(this, 'columnLimit');
      var numFieldLen = Ember.get(this, 'numberFields.length');
      var count = numFieldLen > limit ? limit : numFieldLen;
      var colCount = count ? count + 1 : 2;
      return Ember.String.htmlSafe(colCount);
    }),
    actions: {
      toggleShowingIncomplete: function toggleShowingIncomplete() {
        this.toggleProperty('isShowingIncomplete');
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      var limit;
      var width = window.innerWidth;
      if (width < 413) limit = 3;
      if (width > 414) limit = 4;
      if (width > 672) limit = 6;
      if (width > 960) limit = false;
      this.set('columnLimit', limit);
    }
  });

  _exports.default = _default;
});