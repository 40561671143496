define("fitbase/components/checkin-replies/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this.sortReplies = ["sentAt:desc", "insertedAt:desc"];

      this._super.apply(this, arguments);
    },
    clientEmail: Ember.computed.alias('client.email'),
    classNames: ["card"],
    repliesSorted: Ember.computed('replies.[]', 'client.{hasUncoachedMessage,lastMessageAt,lastCoachedAt}', function () {
      var list = this.get('replies').filterBy('sentAt');
      var hasNew = this.get('client.hasUncoachedMessage');

      if (hasNew) {
        var lastCoachedAt = Ember.Object.create({
          isLastCoachedAt: true,
          sentAt: this.get('client.lastCoachedAt')
        });
        list.pushObject(lastCoachedAt);
      }

      return list.sortBy('sentAt', 'insertedAt').reverse();
    }),
    draft: Ember.computed.alias('checkin.checkinReplyDraft'),
    draftIsSendable: Ember.computed('draft.{id,body}', function () {
      return !Ember.isEmpty(this.draftBody) && !Ember.isEmpty(Ember.get(this, 'draft.id'));
    }),
    draftIsSaving: Ember.computed('draft.{hasDirtyAttributes,isSaving}', function () {
      return Ember.get(this, 'draft.isSaving') && Ember.get(this, 'draft.hasDirtyAttributes');
    }),
    draftBody: Ember.computed('draft.body', function () {
      return Ember.get(this, 'draft.body') || '';
    }),
    actions: {
      sendReply: function sendReply() {
        Ember.get(this, 'sendReply').apply(void 0, arguments);
      },
      updateCoachedAt: function updateCoachedAt() {
        Ember.get(this, 'updateCoachedAt').apply(void 0, arguments);
      }
    }
  });

  _exports.default = _default;
});