define("fitbase/components/input-auth/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    component: 'input-auth',
    classNames: ["input", 'input--layout'],
    classNameBindings: ["isActive:input--active", "isInvalid:input--invalid"],
    attributeBindings: ['component:data-component', 'error:data-error'],
    isActive: Ember.computed('value', 'hasFocus', function () {
      var isActive = !Ember.isEmpty(this.get('value')) || this.get('hasFocus');
      return isActive;
    }),
    isInvalid: Ember.computed('errors.[]', 'error', function () {
      if (this.get('error')) {
        return true;
      }

      return this.get('errors.length') > 0 && this.get('value');
    }),
    inputClass: Ember.computed('value', 'errors.[]', function () {
      var valid = this.get('isInvalid') ? 'invalid' : 'valid';

      if (this.get('value')) {
        return "validate ".concat(valid);
      } else {
        return '';
      }
    }),
    type: "text",
    errorMessages: Ember.computed("errors.[]", function () {
      return (this.get("errors") || []).join(', ');
    }),
    actions: {
      onFocusIn: function onFocusIn() {
        this.set('hasFocus', true);
      },
      onFocusOut: function onFocusOut() {
        this.set('hasFocus', false);
      }
    }
  });

  _exports.default = _default;
});