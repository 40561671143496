define("fitbase/components/checkin-latest-pics/component", ["exports", "fitbase/utils/field-units"], function (_exports, _fieldUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var unitFields = _fieldUnits.default.lookupList('inputType');

  var _default = Ember.Component.extend({
    classNames: ['client-pics', "spaces__space"],
    client: null,
    checkin: null,
    imgixQuery: "?fit=crop&w=340&h=544",
    isVisible: Ember.computed('checkinPictures', function () {
      return !!Ember.get(this, 'checkinPictures.length');
    }),
    checkinPictures: Ember.computed.filter('checkin.checkinFieldValues', function (f) {
      return unitFields[Ember.get(f, 'unit')] === 'image';
    }),
    currentPicIndex: 0,
    animate: true,
    isFirstPicSet: Ember.computed('currentPicIndex', function () {
      return Ember.get(this, 'currentPicIndex') === 0;
    }),
    isLastPicSet: Ember.computed('currentPicIndex', 'checkinPictures.[]', function () {
      return Ember.get(this, 'currentPicIndex') === Ember.get(this, 'checkinPictures.length') - 1;
    }),
    shiftLeft: Ember.computed('currentPicIndex', 'picSetWidth', 'animate', function () {
      var left = Ember.get(this, 'currentPicIndex') * Ember.get(this, 'picSetWidth') * -1;
      var animate = Ember.get(this, 'animate') ? "transition: transform .2s" : '';
      return Ember.String.htmlSafe("transform: translateX(".concat(left, "px); ").concat(animate));
    }),
    actions: {
      nextPic: function nextPic() {
        if (!Ember.get(this, 'isLastPicSet')) {
          this.incrementProperty('currentPicIndex');
        }
      },
      prevPic: function prevPic() {
        if (!Ember.get(this, 'isFirstPicSet')) {
          this.decrementProperty('currentPicIndex');
        }
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.set('animate', false);
      this.set('currentPicIndex', 0);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var width = this.element.offsetWidth;
      this.set('picSetWidth', width);
      this.set('animate', true);
    }
  });

  _exports.default = _default;
});