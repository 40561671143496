define("fitbase/components/header-nav/trainer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ajdbbw+E",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header__brand\"],[8],[7,\"div\",true],[10,\"class\",\"header__logo\"],[8],[1,[28,\"inline-svg\",[\"assets/images/fitspacepro-word\"],[[\"class\"],[\"white\"]]],false],[9],[9],[4,\"if\",[[24,[\"currentUser\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"header-buttons\"],[8],[7,\"div\",true],[10,\"class\",\"header__user header-button\"],[8],[1,[22,\"email\"],false],[9],[7,\"a\",false],[12,\"class\",\"header__logout header-button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"logout\"]],[8],[0,\"logout\"],[9],[9]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/header-nav/trainer/template.hbs"
    }
  });

  _exports.default = _default;
});