define("fitbase/components/latest-reply/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4K4HDrWS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"latest-reply__meta\"],[8],[7,\"span\",true],[10,\"class\",\"latest-reply__author\"],[8],[1,[22,\"authorName\"],false],[0,\" said\"],[9],[7,\"span\",true],[10,\"class\",\"latest-reply__ago\"],[8],[0,\" \"],[1,[28,\"moment-from-now\",[[24,[\"reply\",\"sentAt\"]]],null],false],[9],[7,\"div\",true],[10,\"class\",\"latest-reply__time\"],[8],[1,[28,\"moment-format\",[[24,[\"reply\",\"sentAt\"]],\"MMM D @ hA\"],null],false],[9],[9],[7,\"div\",true],[10,\"class\",\"latest-reply__body\"],[8],[1,[24,[\"reply\",\"body\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/latest-reply/template.hbs"
    }
  });

  _exports.default = _default;
});