define("fitbase/components/checkin-form-progress/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0LRZPSmz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"checkin-form-progress__bar\"],[8],[7,\"div\",true],[11,\"style\",[29,[\"width: \",[22,\"progress\"],\";\"]]],[10,\"class\",\"checkin-form-progress__fill\"],[8],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/checkin-form-progress/template.hbs"
    }
  });

  _exports.default = _default;
});