define("fitbase/components/checkin-latest-number/component", ["exports", "fitbase/utils/field-units"], function (_exports, _fieldUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var suffixes = _fieldUnits.default.lookupList('suffix');

  var _default = Ember.Component.extend({
    classNames: ['current-stat'],
    classNameBindings: ['isFirst:current-stat--main', 'isPositive:current-stat--positive', 'isNegative:current-stat--negative'],
    isFirst: Ember.computed.equal('index', -1),
    isPositive: Ember.computed('fieldValue.delta', function () {
      return Ember.get(this, 'fieldValue.delta') > 0;
    }),
    isNegative: Ember.computed('fieldValue.delta', function () {
      return Ember.get(this, 'fieldValue.delta') < 0;
    }),
    deltaText: Ember.computed('fieldValue.delta', function () {
      var delta = Math.abs(Ember.get(this, 'fieldValue.delta'));
      var suffix = suffixes[Ember.get(this, 'fieldValue.unit')];

      if (delta === 0) {
        return "•••";
      }

      return delta + ' ' + suffix;
    }),
    deltaTitle: Ember.computed('fieldValue.delta', function () {
      if (!Ember.get(this, 'fieldValue.delta')) {
        return "No change";
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});