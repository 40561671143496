define("fitbase/components/checkin-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this.checkinFieldsSort = ['sequence'];

      this._super.apply(this, arguments);
    },
    classNames: ['checkin-form'],
    classNameBindings: ['isIncomplete:checkin-form--no-scroll', 'isFilled:checkin-form--filled'],
    checkinForm: Ember.computed.alias('checkin.checkinForm'),
    checkinFields: Ember.computed.sort('checkinForm.checkinFields', 'checkinFieldsSort'),
    fieldsCount: Ember.computed('checkinFields.[]', function () {
      return this.get('checkinFields.length');
    }),
    save: function save() {},
    // route-action method
    isIncomplete: Ember.computed.not('showingSummary'),
    showingSummaryClient: Ember.computed.or('checkin.{isReviewing,completedAndSaved}'),
    showingSummaryUser: Ember.computed.bool('checkin.isReviewing'),
    showingSummary: Ember.computed('showingSummaryUser', 'showingSummaryClient', function () {
      if (Ember.get(this, 'subject') === "user") {
        return Ember.get(this, 'showingSummaryUser');
      } else {
        return Ember.get(this, 'showingSummaryClient');
      }
    }),
    hasPrev: Ember.computed('prevFieldIndex', function () {
      return this.get('prevFieldIndex') !== false;
    }),
    hasNext: Ember.computed('nextFieldIndex', function () {
      return this.get('nextFieldIndex') !== false;
    }),
    prevFieldIndex: Ember.computed('currentFieldIndex', function () {
      var i = this.get('currentFieldIndex');

      if (i === 0) {
        return false;
      } else {
        return i - 1;
      }
    }),
    currentFieldIndex: Ember.computed('savePosition', 'checkin.currentIndexView', {
      set: function set(key, value) {
        if (!Ember.get(this, 'checkin.completedAndSaved')) {
          this.get('savePosition')(value);
        }

        return value;
      },
      get: function get() {
        var i = this.get('checkin.currentIndexView');
        var len = this.get('fieldsCount');

        if (i > len - 1 || i < 0 || Ember.isEmpty(i)) {
          return 0;
        }

        return i;
      }
    }),
    nextFieldIndex: Ember.computed('currentFieldIndex', function () {
      var i = this.get('currentFieldIndex');

      if (i + 1 === this.get('fieldsCount')) {
        return false;
      } else {
        return i + 1;
      }
    }),
    percentFilled: Ember.computed('checkinFields', 'checkinValues.@each.value', function () {
      var saved = this.get('checkinValues').filter(function (cv) {
        return !Ember.isEmpty(cv.get('value'));
      }).length;
      var count = this.get('fieldsCount');
      var percent = saved / count * 100;
      return percent;
    }),
    isFilled: Ember.computed.equal('percentFilled', 100),
    actions: {
      save: function save() {
        if (Ember.get(this, 'checkin.completedAndSaved') && Ember.get(this, 'subject') !== "user") {
          return false;
        }

        return this.get('save').apply(void 0, arguments);
      },
      next: function next() {
        if (this.get('hasNext')) {
          this.incrementProperty('currentFieldIndex');
        }
      },
      prev: function prev() {
        if (this.get('hasPrev')) {
          this.decrementProperty('currentFieldIndex');
        }
      },
      showSummary: function showSummary() {
        // this is where we change the form to render the data in
        // a table to display their entries
        // for the client to confirm, and click "finish" or "submit""
        //let currentFieldIndex = this.get('currentFieldIndex');
        //let checkinField = this.get('checkinFields').objectAt(currentFieldIndex);
        //let checkinValue = checkinField.get('checkinValue');
        //let value = checkinValue.get('value');
        //this.get('save')(checkinValue, value, checkinField);
        this.set('checkin.isReviewing', true);
      },
      showForm: function showForm() {
        this.set('checkin.isReviewing', false);
      },
      goToField: function goToField(checkinField) {
        var index = Ember.get(this, 'checkinFields').indexOf(checkinField);

        if (index > -1) {
          Ember.setProperties(this, {
            currentFieldIndex: index,
            "checkin.isReviewing": false
          });
        }
      }
    }
  });

  _exports.default = _default;
});