define("fitbase/helpers/client-initials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clientInitials = clientInitials;
  _exports.default = void 0;

  function clientInitials(params
  /*, hash*/
  ) {
    if (params && params[0]) {
      var names = params[0].split(' ');
      var first = names[0];
      var last = names[1];
      return first[0] + (last ? last[0] : '');
    } else {
      return '';
    }
  }

  var _default = Ember.Helper.helper(clientInitials);

  _exports.default = _default;
});