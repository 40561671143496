define("fitbase/components/x-clear/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xO66vFdg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"inline-svg\",[\"assets/images/icon-circle-x\"],null],false],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/x-clear/template.hbs"
    }
  });

  _exports.default = _default;
});