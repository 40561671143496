define("fitbase/components/auth-login/component", ["exports", "fitbase/utils/user-validations", "ember-cp-validations"], function (_exports, _userValidations, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.email': _userValidations.email,
    'model.password': _userValidations.password
  });

  var _default = Ember.Component.extend(Validations, {});

  _exports.default = _default;
});