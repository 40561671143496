define("fitbase/coach/clients/client/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CyWOJIhP",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"clients-nav\",null,[[\"allClients\"],[[24,[\"clients\"]]]]],false],[7,\"main\",true],[10,\"class\",\"column column--center client\"],[8],[7,\"section\",true],[8],[1,[28,\"client-head\",null,[[\"client\"],[[24,[\"model\"]]]]],false],[9],[7,\"section\",true],[8],[7,\"div\",true],[10,\"class\",\"spaces\"],[8],[1,[28,\"checkin-latest-pics\",null,[[\"client\",\"checkin\"],[[24,[\"model\"]],[24,[\"model\",\"checkinLatest\"]]]]],false],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"link section__side-link client__pictures-link\",\"coach.clients.client.checkins.pictures\",[24,[\"model\",\"id\"]]]],{\"statements\":[[0,\"View pictures history\"]],\"parameters\":[]},null],[7,\"h4\",true],[10,\"class\",\"h4\"],[8],[0,\"Notes (private)\"],[9],[7,\"div\",true],[10,\"class\",\"client__info card spaces__space\"],[8],[7,\"div\",true],[10,\"class\",\"content content--tight\"],[8],[7,\"div\",true],[10,\"class\",\"content__cell content__cell--tight\"],[8],[7,\"div\",true],[11,\"class\",[29,[\"input \",[22,\"expandedClass\"]]]],[8],[1,[28,\"textarea-one-way\",null,[[\"value\",\"focusIn\",\"focusOut\",\"spellcheck\",\"autoresize\",\"update\",\"minRows\",\"class\"],[[24,[\"model\",\"notes\"]],[28,\"action\",[[23,0,[]],\"expandNote\"],null],[28,\"action\",[[23,0,[]],\"saveNote\"],null],false,true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"notes\"]]],null]],null],2,\"input__field input__field--pad client__notes loading\"]]],false],[9],[9],[9],[9],[9],[9],[1,[28,\"checkin-latest\",null,[[\"client\",\"checkin\",\"createCheckinForm\",\"updateDraft\",\"sendReply\",\"updateCoachedAt\"],[[24,[\"model\"]],[24,[\"model\",\"checkinLatest\"]],[28,\"route-action\",[\"createCheckinForm\"],null],[28,\"route-action\",[\"updateReplyDraft\"],null],[28,\"route-action\",[\"sendReply\"],null],[28,\"route-action\",[\"updateCoachedAt\"],null]]]],false],[1,[22,\"outlet\"],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/coach/clients/client/index/template.hbs"
    }
  });

  _exports.default = _default;
});