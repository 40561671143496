define("fitbase/components/pics-column-reel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pics-column__reel'],
    attributeBindings: ['style'],
    imageCheckinValues: null,
    reelTop: 0,
    imgixQuery: "?fit=crop&w=340&h=544",
    style: Ember.computed('reelTop', function () {
      var reelTop = Ember.get(this, 'reelTop');
      return Ember.String.htmlSafe("transform: translateY(".concat(reelTop, "%);"));
    })
  });

  _exports.default = _default;
});