define("fitbase/checkin-field/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    text: attr('string'),
    unit: attr('string'),
    sequence: attr('number'),
    archivedAt: attr('date'),
    _imported: false,
    // relationships
    checkinForm: belongsTo('checkin-form'),
    checkinValue: belongsTo('checkin-value')
  });

  _exports.default = _default;
});