define("fitbase/mixins/authenticated-trainer-route-mixin", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    authenticationRoute: 'auth.login',
    isAuthenticated: Ember.computed('session.{subject,isAuthenticated}', function () {
      var isAuthed = Ember.get(this, 'session.isAuthenticated');

      if (isAuthed) {
        var _EmberGet = Ember.get(this, 'session.subject'),
            _EmberGet2 = _slicedToArray(_EmberGet, 1),
            model = _EmberGet2[0];

        return model === 'user';
      } else {
        return false;
      }
    }),
    beforeModel: function beforeModel() {
      if (!Ember.get(this, 'isAuthenticated')) {
        if (Ember.get(this, 'session.isAuthenticated')) {
          Ember.get(this, 'session').invalidate();
        }

        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});