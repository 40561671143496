define("fitbase/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3cgh/jRx",
    "block": "{\"symbols\":[\"flash\",\"component\",\"flash\",\"close\"],\"statements\":[[1,[22,\"outlet\"],false],[7,\"div\",true],[10,\"class\",\"footer\"],[8],[9],[7,\"div\",true],[10,\"id\",\"app-modal\"],[8],[9],[7,\"div\",true],[10,\"id\",\"info-modal\"],[8],[9],[4,\"if\",[[24,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"flash-messages flash-messages--top-right\"],[8],[4,\"each\",[[24,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[4,\"flash-message\",null,[[\"flash\"],[[23,1,[]]]],{\"statements\":[[1,[28,\"flash-message-icon\",null,[[\"type\"],[[23,3,[\"type\"]]]]],false],[7,\"div\",true],[10,\"class\",\"flash-message__content\"],[8],[7,\"div\",true],[10,\"class\",\"flash-content\"],[8],[7,\"h6\",true],[8],[1,[23,3,[\"title\"]],false],[9],[7,\"p\",true],[8],[1,[23,3,[\"message\"]],false],[9],[9],[9],[7,\"div\",false],[12,\"class\",\"flash-message__icon\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[23,4,[]]]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-close\"],[8],[9],[9],[4,\"if\",[[23,2,[\"showProgressBar\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"alert-progress\"],[8],[7,\"div\",true],[11,\"style\",[23,2,[\"progressDuration\"]]],[10,\"class\",\"alert-progressBar\"],[8],[9],[9]],\"parameters\":[]},null]],\"parameters\":[2,3,4]},null]],\"parameters\":[1]},null],[9]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/application/template.hbs"
    }
  });

  _exports.default = _default;
});