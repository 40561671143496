define("fitbase/coach/clients/index/route", ["exports", "fitbase/mixins/authenticated-trainer-route-mixin"], function (_exports, _authenticatedTrainerRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedTrainerRouteMixin.default, {});

  _exports.default = _default;
});