define("fitbase/components/field-schema-sortable/component", ["exports", "fitbase/utils/field-units"], function (_exports, _fieldUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var alias = Ember.computed.alias;

  var _default = Ember.Component.extend({
    classNames: ['field-schema', 'field-schema--sortable'],
    attributeBindings: ['data-num'],
    label: alias('schema.text'),
    unit: alias('schema.unit'),
    unitLabel: Ember.computed('unit', function () {
      return _fieldUnits.default.lookupList('label')[Ember.get(this, 'unit')];
    }),
    "data-num": Ember.computed('index', function () {
      return Ember.get(this, 'index') + 1;
    })
  });

  _exports.default = _default;
});