define("fitbase/components/checkin-field-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pkIcJ9gP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"checkin-input__label checkin-input__label--image\"],[8],[1,[22,\"label\"],false],[9],[7,\"div\",true],[10,\"class\",\"checkin-input__help\"],[8],[1,[22,\"help\"],false],[9],[7,\"div\",true],[10,\"class\",\"checkin-input__field-wrap\"],[8],[7,\"div\",true],[10,\"class\",\"checkin-upload\"],[8],[7,\"div\",true],[11,\"data-progress\",[22,\"progress\"]],[11,\"class\",[29,[\"checkin-upload__drop-zone \",[22,\"imageClass\"],\" \",[22,\"uploadingClass\"]]]],[8],[4,\"file-upload\",null,[[\"name\",\"accept\",\"onfileadd\"],[[24,[\"name\"]],\"image/*\",[28,\"action\",[[23,0,[]],\"upload\"],null]]],{\"statements\":[[4,\"if\",[[24,[\"isUploading\"]]],null,{\"statements\":[[1,[28,\"inline-svg\",[\"fitbase-logo.svg\"],[[\"class\"],[\"checkin-upload__spinner\"]]],false]],\"parameters\":[]},null],[4,\"if\",[[24,[\"image\"]]],null,{\"statements\":[[7,\"img\",true],[11,\"src\",[29,[[22,\"image\"],[22,\"imgixQuery\"]]]],[10,\"class\",\"checkin-upload__image\"],[8],[9]],\"parameters\":[]},null],[7,\"a\",true],[10,\"class\",\"file-click\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[9]],\"parameters\":[]},null],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/checkin-field-image/template.hbs"
    }
  });

  _exports.default = _default;
});