define("fitbase/components/modal-confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JQVUtpuB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-overlay\"],[8],[9],[7,\"div\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"didClickOff\"],null]],[10,\"class\",\"modal-wrapper\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[7,\"div\",true],[11,\"class\",[29,[\"modal \",[22,\"activeClass\"],\" \",[22,\"modalStyleClass\"]]]],[8],[7,\"div\",true],[10,\"class\",\"modal__content\"],[8],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"modal__icon\"],[8],[1,[28,\"inline-svg\",[[24,[\"icon\"]]],[[\"class\"],[\"svg-icon--inline\"]]],false],[9]],\"parameters\":[]},null],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"h2 modal__title\"],[8],[1,[22,\"title\"],false],[9]],\"parameters\":[]},null],[4,\"if\",[[24,[\"message\"]]],null,{\"statements\":[[7,\"p\",true],[10,\"class\",\"p\"],[8],[1,[22,\"message\"],false],[9]],\"parameters\":[]},{\"statements\":[[7,\"div\",true],[10,\"class\",\"modal__yield-block\"],[8],[14,1],[9]],\"parameters\":[]}],[7,\"center\",true],[10,\"class\",\"buttons clearfix\"],[8],[4,\"if\",[[24,[\"cancelText\"]]],null,{\"statements\":[[7,\"div\",false],[12,\"class\",\"button button--subtle\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[1,[22,\"cancelText\"],false],[9]],\"parameters\":[]},null],[4,\"if\",[[24,[\"confirmText\"]]],null,{\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"button \",[22,\"modalButtonClass\"]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"confirm\"]],[8],[1,[22,\"confirmText\"],false],[9]],\"parameters\":[]},null],[9],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/modal-confirm/template.hbs"
    }
  });

  _exports.default = _default;
});