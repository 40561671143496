define("fitbase/components/checkins-li/component", ["exports", "ember-click-outside/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mixin.default, {
    init: function init() {
      this.sort = ["sequence:asc"];

      this._super.apply(this, arguments);
    },
    classNames: ['checkins-li'],
    classNameBindings: ['isShowingNotes', 'isShowingExpanded', 'isIncomplete:checkins-li--incomplete', 'hasNotes:checkins-li--has-notes', 'hasReplies:checkins-li--has-replies', 'hasExpandedData:checkins-li--has-expand'],
    checkinDate: Ember.computed.alias('checkin.dueAt'),
    checkinFieldValuesSorted: Ember.computed.sort('checkinFieldValues', 'sort'),
    checkinNumbers: Ember.computed.filterBy('checkinFieldValuesSorted', 'inputType', 'number'),
    checkinNotes: Ember.computed.filter('checkinFieldValuesSorted', function (f) {
      return ["text", "note"].indexOf(Ember.get(f, 'inputType')) >= 0;
    }),
    hasNotes: Ember.computed('checkinNotes.length', function () {
      return !!Ember.get(this, 'checkinNotes.length');
    }),
    hasReplies: Ember.computed('checkinReplies.length', function () {
      return !!Ember.get(this, 'checkinReplies.length');
    }),
    checkinReplies: Ember.computed('checkin.checkinReplies.[]', function () {
      return this.get('checkin.checkinReplies').sortBy('sentAt');
    }),
    hasExpandedData: Ember.computed.or('hasReplies', 'hasNotes'),
    isShowingReplies: false,
    isShowingNotes: Ember.computed.alias('isShowingExpanded'),
    isShowingActions: false,
    isIncomplete: Ember.computed.not('checkin.completedAt'),
    checkinLink: Ember.computed('checkin.{id,token}', function () {
      var link = document.createElement('a');
      link.href = window.location;
      link.pathname = "/checkin-link/".concat(Ember.get(this, 'checkin.id'), "/").concat(Ember.get(this, 'checkin.token'));
      return link.href;
    }),
    actions: {
      didClickNotes: function didClickNotes() {
        this.set('isShowingActions', false);
      },
      didClickToggleReplies: function didClickToggleReplies() {
        this.toggleProperty('isShowingReplies');
      },
      didClickCopyLink: function didClickCopyLink() {
        var _this = this;

        var el = document.querySelector('.checkin-action__url');
        var oldContentEditable = el.contentEditable,
            oldReadOnly = el.readOnly,
            range = document.createRange();
        el.contenteditable = true;
        el.readonly = false;
        range.selectNodeContents(el);
        var s = window.getSelection();
        s.addRange(range);
        el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

        el.contentEditable = oldContentEditable;
        el.readOnly = oldReadOnly;

        if (document.execCommand('copy')) {
          document.execCommand("unselect");
          this.set('didCopyLink', true);
          Ember.run.later(function () {
            _this.set('isShowingActions', false);

            _this.set('didCopyLink', false);
          }, 700);
        }
      },
      didClickNumbers: function didClickNumbers() {
        this.set('isShowingActions', false);

        if (!Ember.get(this, 'hasExpandedData')) {
          return;
        }

        this.toggleProperty("isShowingExpanded");
        this.set('isShowingReplies', false);
      },
      showActions: function showActions() {
        this.toggleProperty('isShowingActions');
      }
    },
    clickOutside: function clickOutside() {
      this.set('isShowingExpanded', false);
      this.set('isShowingReplies', false);
      this.set('isShowingActions', false);
    },
    didInsertElement: function didInsertElement() {
      Ember.run.next(this, this.addClickOutsideListener);
    },
    willDestroyElement: function willDestroyElement() {
      this.removeClickOutsideListener();
    }
  });

  _exports.default = _default;
});