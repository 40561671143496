define("fitbase/coach/clients/client/edit/checkin-form/create-template/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    fields: Ember.computed('model.fields', function () {
      var fieldsMap = Ember.get(this, 'model.fields');
      return Object.keys(fieldsMap).map(function (k) {
        return fieldsMap[k];
      }).sort(function (a, b) {
        if (a.sequence == b.sequence) return 0;
        if (a.sequence < b.sequence) return -1;
        if (a.sequence > b.sequence) return 1;
      });
    })
  });

  _exports.default = _default;
});