define("fitbase/index/route", ["exports", "fitbase/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    redirect: function redirect() {
      return this.transitionTo('coach.clients');
    }
  });

  _exports.default = _default;
});