define("fitbase/lib/fields-map-old", ["exports", "fitbase/lib/field-schema"], function (_exports, _fieldSchema) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONArray = Ember.ArrayProxy.extend({
    hasInvalidFields: Ember.computed('content.@each.isInvalid', function () {
      return this.any(function (field) {
        return field.get('isInvalid');
      });
    }),
    clean: function clean() {
      var content = this.get('content');

      if (Ember.isArray(content)) {
        var filteredContent = content.filterBy('isValid', true);
        this.set('content', filteredContent);
      }
    },
    toString: function toString() {
      return JSON.stringify(this.get('content'));
    },
    addOne: function addOne(obj) {
      var field;

      if (Ember.isNone(obj)) {
        field = _fieldSchema.default.create();
      } else {
        field = _fieldSchema.default.create(obj);
      }

      this.pushObject(field);
    }
  });
  JSONArray.reopenClass({
    create: function create() {
      var A = arguments.length <= 0 ? undefined : arguments[0];

      if (Ember.isNone(arguments.length <= 0 ? undefined : arguments[0])) {
        A = [];
      }

      if (Ember.isArray(A)) {
        return this._super({
          content: arguments.length <= 0 ? undefined : arguments[0]
        });
      }

      throw Error;
    },
    fromJson: function fromJson(jsonString) {
      var pojo, schemas;

      if (Ember.isNone(jsonString)) {
        return null;
      }

      try {
        pojo = JSON.parse(jsonString);
      } catch (err) {
        pojo = null;
      }

      if (!Ember.isNone(pojo) && Ember.isArray(pojo)) {
        schemas = pojo.map(function (f) {
          return _fieldSchema.default.create(f);
        });
      }

      return this.create(schemas);
    }
  });
  var _default = JSONArray;
  _exports.default = _default;
});