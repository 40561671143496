define("fitbase/components/latest-reply/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    isExpanded: false,
    classNames: ['latest-reply'],
    classNameBindings: ['isExpanded:latest-reply--expanded', 'authorCss'],
    authorCss: Ember.computed('reply.author', function () {
      var author = this.reply.get('author') || 'user';
      return "is-".concat(author);
    }),
    author: Ember.computed('reply.{author,client,user}', 'session.currentUser', function () {
      var authorString = Ember.get(this, 'reply.author') == 'client' ? 'client' : 'user';
      return Ember.get(this, "reply.".concat(authorString));
    }),
    authorIsSelf: Ember.computed('reply.author', 'session.subjectType', function () {
      return Ember.get(this, 'session.subjectType') == Ember.get(this, 'reply.author');
    }),
    authorName: Ember.computed('author', function () {
      if (this.authorIsSelf) {
        return 'you';
      } else {
        return this.author.get('name');
      }
    }),
    click: function click() {
      this.toggleProperty('isExpanded');
    }
  });

  _exports.default = _default;
});