define("fitbase/components/field-schema/component", ["exports", "fitbase/utils/field-units", "ember-click-outside/mixin"], function (_exports, _fieldUnits, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mixin.default, {
    tagName: 'div',
    classNames: ['field-schema'],
    classNameBindings: ['isImage:field-schema--image', 'isInvalid:field-schema--invalid', 'isFocused:field-schema--focused'],
    schema: null,
    label: Ember.computed.alias('schema.text'),
    labelPlaceholder: null,
    help: Ember.computed.alias('schema.help'),
    helpPlaceholder: null,
    unit: Ember.computed.alias('schema.unit'),
    unitOptions: _fieldUnits.default.selectOptions('label'),
    isValid: Ember.computed.alias('schema.isValid'),
    isNew: Ember.computed.alias('schema.isNew'),
    isInvalid: Ember.computed.equal('isValid', false),
    isVisible: Ember.computed.not('field.isDeleted'),
    isFocused: false,
    isPromptingRemove: false,
    skipBlur: false,
    save: function save() {},
    // pass in route-action
    remove: function remove() {},
    // pass in from parent context
    actions: {
      archive: function archive() {
        alert('Archive Error. Please contact support@fitspacepro.com');
      },
      promptRemove: function promptRemove() {
        if (this.isNew) {
          return this.send('confirmRemove');
        }

        return this.set('isPromptingRemove', true);
      },
      abortRemove: function abortRemove() {
        this.set('isPromptingRemove', false);
      },
      confirmRemove: function confirmRemove() {
        this.remove(this.get('schema'));
      },
      enter: function enter() {
        this.clearFocused();
        Ember.get(this, 'enter')();
      }
    },
    _skipBlur: function _skipBlur() {
      Ember.set(this, 'skipBlur', true);
    },
    click: function click() {
      this.clearFocused();
      Ember.setProperties(this, {
        skipBlur: false,
        isFocused: true
      });
    },
    clickOutside: function clickOutside() {
      Ember.set(this, 'isFocused', false);
    },
    mouseDown: function mouseDown(e) {
      if (e.currentTarget === this.element) {
        Ember.set(this, "skipBlur", true);
      }
    },
    clearFocused: function clearFocused() {
      var focusedEl = document.querySelector('.field-schema.field-schema--focused');

      if (focusedEl && focusedEl != this.element) {
        focusedEl.blur();
      }
    },
    focusIn: function focusIn() {
      this.clearFocused();
      Ember.set(this, 'isFocused', true);
    },
    focusOut: function focusOut(e) {
      var noRelatedTarget = !this.element.contains(e.relatedTarget);
      var notSkipBlur = !Ember.get(this, 'skipBlur');
      var wasFocused = Ember.get(this, 'isFocused');

      if (noRelatedTarget && notSkipBlur) {
        Ember.set(this, 'isFocused', false);
        Ember.set(this, 'skipBlur', false);

        if (wasFocused) {
          Ember.get(this, "save")(Ember.get(this, "schema"));
        }
      }
    },
    keyUpSelect: function keyUpSelect(e) {
      if (e.keyCode === 13) {
        this.get('parentView.enter')();
      }
    },
    didInsertElement: function didInsertElement() {
      var isNew = Ember.get(this, 'isNew');
      var isImported = Ember.get(this, 'schema._imported');

      if (isNew && !isImported) {
        this.element.querySelector('textarea').focus();
      }

      Ember.run.next(this, this.addClickOutsideListener);
    },
    willDestroyElement: function willDestroyElement() {
      this.removeClickOutsideListener();
    }
  });

  _exports.default = _default;
});