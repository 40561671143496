define("fitbase/components/set-frequency/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IpTAq8pI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"set-frequency\"],[8],[7,\"div\",true],[10,\"class\",\"set-frequency__title\"],[8],[0,\"Frequency\"],[9],[7,\"div\",true],[10,\"class\",\"set-frequency__options\"],[8],[7,\"button\",false],[12,\"class\",\"set-frequency__option\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"frequency\"]]],null],7]],[8],[0,\"Weekly\"],[1,[28,\"inline-svg\",[[24,[\"isWeeklySvg\"]]],[[\"class\"],[\"svg-icon\"]]],false],[9],[7,\"button\",false],[12,\"class\",\"set-frequency__option\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"frequency\"]]],null],14]],[8],[0,\"Bi-monthly\"],[1,[28,\"inline-svg\",[[24,[\"isBiMonthlySvg\"]]],[[\"class\"],[\"svg-icon\"]]],false],[9],[7,\"button\",false],[12,\"class\",\"set-frequency__option\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"frequency\"]]],null],28]],[8],[0,\"Monthly\"],[1,[28,\"inline-svg\",[[24,[\"isMonthlySvg\"]]],[[\"class\"],[\"svg-icon\"]]],false],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/set-frequency/template.hbs"
    }
  });

  _exports.default = _default;
});