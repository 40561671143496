define("fitbase/checkin-template/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // CheckinTemplate
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    name: attr('string'),
    fields: attr(),
    user: belongsTo('user'),
    setFields: function setFields(fieldModelsArray) {
      var fieldsObj = {};
      fieldModelsArray.forEach(function (field, i) {
        var attrs = field.serialize()["data"]["attributes"];
        fieldsObj[i] = {
          text: attrs.text,
          unit: attrs.unit,
          sequence: attrs.sequence
        };
      });
      this.set('fields', fieldsObj);
    }
  });

  _exports.default = _default;
});