define("fitbase/components/checkin-intro/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['checkin-intro'],
    isReviewing: Ember.computed.or('checkin.{isReviewing,completedAndSaved}'),
    isNotReviewing: Ember.computed.not('isReviewing'),
    isFilled: Ember.computed.equal('percent', 100),
    isEditingAndFilled: Ember.computed.and('isNotReviewing', 'isFilled'),
    title: Ember.computed('isReviewing', 'percent', function () {
      switch (true) {
        case Ember.get(this, 'isReviewing'):
          return "Great job";

        case Ember.get(this, 'isEditingAndFilled'):
          return "You answered them all";

        default:
          return "It's time to check in!";
      }
    }),
    instruction: Ember.computed('isReviewing', 'percent', function () {
      switch (true) {
        case Ember.get(this, 'isReviewing'):
          return "Here's a summary...";

        case Ember.get(this, 'isEditingAndFilled'):
          return "Click done to review your responses";

        default:
          return 'Fill out the form below...';
      }
    })
  });

  _exports.default = _default;
});