define("fitbase/components/checkin-history-row/component", ["exports", "ember-click-outside/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mixin.default, {
    init: function init() {
      this.sort = ["sequence:asc"];

      this._super.apply(this, arguments);
    },
    tagName: 'tbody',
    classNames: ['checkin-history__row'],
    classNameBindings: ['isShowingNotes', 'isIncomplete:checkin-history__row--incomplete', 'hasNotes:checkin-history__row--has-notes'],
    numbersColspan: Ember.computed('colspan', function () {
      return Ember.get(this, 'colspan') - 1;
    }),
    checkinDate: Ember.computed.alias('checkin.dueAt'),
    checkinFieldValuesSorted: Ember.computed.sort('checkinFieldValues', 'sort'),
    checkinNumbers: Ember.computed.filterBy('checkinFieldValuesSorted', 'inputType', 'number'),
    checkinNotes: Ember.computed.filter('checkinFieldValuesSorted', function (f) {
      return ["text", "note"].indexOf(Ember.get(f, 'inputType')) >= 0;
    }),
    hasNotes: Ember.computed('checkinNotes.length', function () {
      return !!Ember.get(this, 'checkinNotes.length');
    }),
    isShowingNotes: false,
    isIncomplete: Ember.computed.not('checkin.completedAt'),
    click: function click() {
      if (!Ember.get(this, 'hasNotes')) {
        return;
      }

      this.toggleProperty("isShowingNotes");
    },
    clickOutside: function clickOutside() {
      this.set('isShowingNotes', false);
    },
    didInsertElement: function didInsertElement() {
      Ember.run.next(this, this.addClickOutsideListener);
    },
    willDestroyElement: function willDestroyElement() {
      this.removeClickOutsideListener();
    }
  });

  _exports.default = _default;
});