define("fitbase/coach/clients/client/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    noteExpanded: false,
    expandedClass: Ember.computed('noteExpanded', function () {
      if (!Ember.get(this, 'noteExpanded')) {
        return 'is-collapsed';
      } else {
        return 'is-collapsible';
      }
    }),
    actions: {
      saveNote: function saveNote() {
        Ember.set(this, 'noteExpanded', false);
        Ember.get(this, 'model').save();
      },
      expandNote: function expandNote() {
        Ember.set(this, 'noteExpanded', true);
      }
    },
    deactivate: function deactivate() {
      Ember.set(this, 'noteExpanded', false);
    }
  });

  _exports.default = _default;
});