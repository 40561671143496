define("fitbase/components/input-one-way/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var KEY_EVENTS = {
    13: 'enter',
    27: 'cancel'
  };

  var _default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['type', 'value', 'placeholder', 'data-stripe', 'name', 'disabled', 'autocomplete'],
    type: 'text',
    _sanitizedValue: undefined,
    touchMoved: false,
    autocomplete: "off",
    input: function input() {
      this._handleChangeEvent();
    },
    change: function change() {
      this._handleChangeEvent();
    },
    keyUp: function keyUp(event) {
      this._interpretKeyUp(event);
    },
    keyPress: function keyPress() {},
    touchStart: function touchStart() {
      this.set('touchMoved', false);
    },
    touchMove: function touchMove() {
      this.set('touchMoved', true);
    },
    touchEnd: function touchEnd(e) {
      if (this.get('touchMoved')) {
        e.stopPropagation();
      }
    },
    _interpretKeyUp: function _interpretKeyUp(e) {
      var method = KEY_EVENTS[e.keyCode];

      if (method && typeof this[method] == "function") {
        return this[method](e);
      }
    },
    _handleChangeEvent: function _handleChangeEvent() {
      var value = this.readDOMAttr('value');

      this._processNewValue.call(this, value);
    },
    _processNewValue: function _processNewValue(rawValue) {
      var sanitized = this.sanitizeInput(rawValue);

      if (rawValue !== sanitized) {
        this.element.value = sanitized;
      }

      this.get('update')(sanitized);
    },
    sanitizeInput: function sanitizeInput(input) {
      var sanitized = input;

      if (this.get('filter') && input) {
        var filter = this.get('filter');
        var regex = new RegExp(filter, "g");
        sanitized = input.replace(regex, '');
      }

      return sanitized;
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('update')) {
        throw new Error("You must provide an `update` action to `{{".concat(this.templateName, "}}`."));
      }

      this._processNewValue.call(this, this.get('value'));
    }
  });

  _exports.default = _default;
});