define("fitbase/coach/clients/client/edit/checkin-form/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var alias = Ember.computed.alias;

  var _default = Ember.Controller.extend({
    init: function init() {
      this.checkinFieldsSortOrder = ["sequence", "id"];

      this._super.apply(this, arguments);

      this.center = (0, _moment.default)();
    },
    now: (0, _moment.default)().hours(0).minute(0).seconds(0).milliseconds(0).toDate(),
    today: (0, _moment.default)().add(1, "days").hours(0).minutes(0).seconds(0).milliseconds(0).toDate(),
    minEndAt: Ember.computed('model.{nextAt,frequency}', function () {
      var nextAt = this.get('model.nextAt');
      var frequency = this.get('model.frequency') || 1;

      if (nextAt) {
        return (0, _moment.default)(nextAt).add(frequency, "days").hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
      } else {
        return this.get('today');
      }
    }),
    setNextAt: function setNextAt(date) {
      var now = (0, _moment.default)(new Date());
      date = (0, _moment.default)(date);

      if (_moment.default.compare(date, now) == -1) {
        this.set('intendedNextAt', date);
        this.set('showModalRequireFuture', true);
      } else {
        this.set('model.nextAt', date.toDate());
      }
    },
    nextAt: Ember.computed.alias('model.nextAt'),
    nextAtText: Ember.computed('model.{frequency,nextAt}', function () {
      var nextAt = this.get('model.nextAt') ? (0, _moment.default)(this.get('model.nextAt')) : 'not set';
      var frequencies = {
        "7": "weekly",
        "14": "every 2 weeks",
        "28": "every 4 weeks"
      };
      return {
        date: nextAt.format('dddd, MMM Do'),
        frequency: frequencies[this.get('model.frequency')],
        time: nextAt.format('ha')
      };
    }),
    checkinFrequencyOptions: Ember.A([{
      label: "Weekly",
      value: 7
    }, {
      label: "Every 2 weeks",
      value: 14
    }, {
      label: "Every 4 weeks",
      value: 28
    }]),
    client: alias('model.client'),
    checkinForm: alias('model'),
    checkinFields: Ember.computed.sort('checkinForm.checkinFields', 'checkinFieldsSortOrder'),
    isSorting: false,
    shouldSave: Ember.computed('checkinForm.{hasDirtyAttributes,shouldSave}', function () {
      return Ember.get(this, 'checkinForm.hasDirtyAttributes') || Ember.get(this, 'checkinForm.shouldSave');
    }),
    nextSequence: Ember.computed('checkinForm.checkinFields.[]', function () {
      return Ember.get(this, 'checkinForm.checkinFields.length') + 1;
    }),
    classSaveButton: Ember.computed('checkinForm.hasDirtyAttributes', function () {
      return Ember.get(this, 'checkinForm.hasDirtyAttributes') ? 'button--primary' : '';
    }),
    saveButtonText: Ember.computed('checkinForm.hasDirtyAttributes', function () {
      return Ember.get(this, 'checkinForm.hasDirtyAttributes') ? 'save' : 'done';
    }),
    actions: {
      reorderFields: function reorderFields(fields) {
        Ember.set(this, 'checkinFieldsSorted', fields);
      },
      onSelectHour: function onSelectHour(hour) {
        var newDate = new Date(this.nextAt);
        newDate.setHours(hour);
        this.setNextAt(newDate);
      },
      startSorting: function startSorting() {
        this.get('checkinFields').filterBy('isNew', true).invoke('destroyRecord');
        var fieldsCopy = Ember.A(Ember.get(this, 'checkinFields').map(function (m) {
          return m;
        }));
        Ember.set(this, 'checkinFieldsSorted', fieldsCopy);
        Ember.set(this, 'isSorting', true);
      },
      cancelSorting: function cancelSorting() {
        Ember.set(this, 'isSorting', false);
      },
      saveSorting: function saveSorting() {
        var _this = this;

        var fields = Ember.get(this, 'checkinFieldsSorted');
        var order = fields.map(function (field, i) {
          return {
            id: Ember.get(field, "id"),
            sequence: i
          };
        });
        var checkinForm = Ember.get(this, 'checkinForm');
        checkinForm.setProperties({
          checkinFields: fields,
          fieldsOrder: order
        });
        checkinForm.save().then(function () {
          return _this.set('isSorting', false);
        });
      },
      save: function save() {
        return true;
      },
      onSelectNextAt: function onSelectNextAt(input) {
        var now = new Date();
        var isToday = _moment.default.compare((0, _moment.default)(input).startOf('day'), (0, _moment.default)(now).startOf('day')) === 0;

        if (isToday) {
          input.setHours(now.getHours() + 1);
        }

        this.set('model.nextAt', input);
      },
      disableDayFn: function disableDayFn(date) {
        var frequency = this.get('model.frequency');
        var nextAt = this.get('model.nextAt');
        var diff = (0, _moment.default)(nextAt).diff(date, 'days');
        return diff > 0 || diff % frequency !== 0;
      }
    }
  });

  _exports.default = _default;
});