define("fitbase/coach/settings/security/controller", ["exports", "fitbase/utils/user-validations", "ember-cp-validations"], function (_exports, _userValidations, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.password': _userValidations.editPassword
  });

  var _default = Ember.Controller.extend(Validations, {
    showValidations: false,
    actions: {
      save: function save() {
        if (this.get('validations.isValid')) {
          return true;
        } else {
          this.set('showValidations', true);
        }
      }
    }
  });

  _exports.default = _default;
});