define("fitbase/components/pics-column-reel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Fedul70",
    "block": "{\"symbols\":[\"image\",\"i\"],\"statements\":[[4,\"each\",[[24,[\"imageCheckinValues\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"value\"]]],null,{\"statements\":[[7,\"div\",true],[11,\"style\",[29,[\"background-image:url(\",[23,1,[\"value\"]],[22,\"imgixQuery\"],\")\"]]],[10,\"class\",\"pics-column__pic\"],[8],[9]],\"parameters\":[]},{\"statements\":[[7,\"div\",true],[11,\"data-date\",[29,[\"Skipped \",[28,\"moment-format\",[[23,1,[\"checkin\",\"dueAt\"]],\"MMM DD, YYYY\"],null]]]],[10,\"class\",\"pics-column__pic pics-column__pic--empty\"],[8],[9]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/pics-column-reel/template.hbs"
    }
  });

  _exports.default = _default;
});