define("fitbase/coach/clients/client/checkins/pictures/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this.sortCheckinBy = ['dueAt:asc'];

      this._super.apply(this, arguments);
    },
    completedCheckins: Ember.computed.filterBy('model.checkins', 'completedAt'),
    sortedCheckins: Ember.computed.sort('completedCheckins', 'sortCheckinBy'),
    checkins: Ember.computed('sortedCheckins', 'currentField', function () {
      var _this = this;

      return Ember.get(this, 'sortedCheckins').filter(function (checkin) {
        var checkinValue = Ember.get(checkin, 'checkinFieldValues').find(function (fv) {
          return Ember.get(fv, 'id').split('-')[1] == Ember.get(_this, 'currentField.id');
        });
        return !Ember.isEmpty(Ember.get(checkinValue, 'value'));
      });
    }),
    imageFields: Ember.computed.filter('model.checkinFields', function (field) {
      return Ember.get(field, 'unit') == 'image';
    }),
    selectedField: null,
    fieldOptions: Ember.computed.map('imageFields', function (field) {
      return {
        value: field,
        label: Ember.get(field, 'text')
      };
    }),
    currentField: Ember.computed('selectedField', 'imageCheckinValues.[]', function () {
      var selectedField = Ember.get(this, 'selectedField');

      if (!Ember.isEmpty(selectedField)) {
        return selectedField;
      } else {
        return Ember.get(this, 'imageFields.firstObject');
      }
    }),
    imageCheckinValues: Ember.computed('checkins', 'checkins.[]', 'currentField', function () {
      var _this2 = this;

      return Ember.get(this, 'checkins').map(function (checkin) {
        return Ember.get(checkin, 'checkinFieldValues').find(function (fv) {
          return Ember.get(fv, 'id').split('-')[1] == Ember.get(_this2, 'currentField.id');
        });
      });
    }),
    actions: {
      selectField: function selectField(field) {
        this.set('selectedField', field);
      }
    }
  });

  _exports.default = _default;
});