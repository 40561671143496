define("fitbase/components/checkin-latest/component", ["exports", "fitbase/utils/field-units", "moment"], function (_exports, _fieldUnits, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var unitFields = _fieldUnits.default.lookupList('inputType');

  var _default = Ember.Component.extend({
    init: function init() {
      this.sort = ["sequence:asc"];

      this._super.apply(this, arguments);
    },
    tagName: 'section',
    classNames: ['checkin-latest'],
    client: null,
    checkin: null,
    completedDate: Ember.computed('checkin.completedAt', function () {
      var date = (0, _moment.default)(Ember.get(this, 'checkin.completedAt'));
      return date.format("dddd - MMMM D, Y @ ha ");
    }),
    checkinNeedsSetup: Ember.computed.empty('client.checkinForm.id'),
    numbersSorted: Ember.computed.sort('checkinNumbers', 'sort'),
    checkinNumbers: Ember.computed.filter('checkin.checkinFieldValues', function (f) {
      return unitFields[Ember.get(f, 'unit')] === "number";
    }),
    numbersSortedSlice: Ember.computed('numbersSorted', function () {
      return Ember.get(this, 'numbersSorted').slice(1);
    }),
    numberSlicePage: 1,
    sliceMaxLength: 6,
    numberSlicePages: Ember.computed('sliceMaxLength', 'checkinNumbers.[]', function () {
      return Math.ceil((Ember.get(this, 'checkinNumbers.length') - 1) / Ember.get(this, 'sliceMaxLength')) || 1;
    }),
    numberSliceIndex: Ember.computed('numberSlicePage', 'numberSlicePages', 'checkinNumbers.length', 'sliceMaxLength', function () {
      var maxLength = Ember.get(this, 'sliceMaxLength'),
          page = Ember.get(this, 'numberSlicePage'),
          pages = Ember.get(this, 'numberSlicePages'),
          isTail = page === pages;

      if (pages > 1) {
        if (isTail) {
          return Ember.get(this, 'checkinNumbers.length') - 1 - maxLength;
        } else {
          return (page - 1) * maxLength;
        }
      } else {
        return 0;
      }
    }),
    isInitialNumbers: Ember.computed('numberSlicePage', 'numberSlicePages', function () {
      return Ember.get(this, 'numberSlicePage') === 1;
    }),
    isTailNumbers: Ember.computed('numberSlicePage', 'numberSlicePages', function () {
      return Ember.get(this, 'numberSlicePage') === Ember.get(this, 'numberSlicePages');
    }),
    sliceLeft: Ember.computed('numberSliceIndex', function () {
      return Ember.get(this, 'numberSliceIndex') * 88;
    }),
    notesSorted: Ember.computed.sort('checkinNotes', 'sort'),
    checkinNotes: Ember.computed.filter('checkin.checkinFieldValues', function (f) {
      return ["text", "note"].indexOf(unitFields[Ember.get(f, 'unit')]) >= 0;
    }),
    checkinSectionTitle: Ember.computed('checkinNeedsSetup', 'client.firstName', 'checkin.{completedAt,isFulfilled}', function () {
      if (Ember.get(this, 'checkinNeedsSetup')) {
        return "You need to setup ".concat(Ember.get(this, 'client.firstName'), "'s check-ins");
      }

      if (Ember.get(this, 'checkin.completedAt')) {
        return 'Latest check-in';
      }

      if (Ember.get(this, 'checkin.isFulfilled')) {
        return "".concat(Ember.get(this, 'client.firstName'), " hasn't checked in yet.");
      }

      return '';
    }),
    actions: {
      createCheckinForm: function createCheckinForm() {
        this.get('createCheckinForm')();
      },
      nextNumberPage: function nextNumberPage() {
        if (Ember.get(this, 'numberSlicePage') <= Ember.get(this, 'numberSlicePages')) {
          this.incrementProperty('numberSlicePage');
        }
      },
      prevNumberPage: function prevNumberPage() {
        if (Ember.get(this, 'numberSlicePage') > 1) {
          this.decrementProperty('numberSlicePage');
        }
      }
    }
  });

  _exports.default = _default;
});