define("fitbase/components/pics-column/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pics-column'],
    checkins: null,
    checkinOptions: Ember.computed.map('checkins', function (checkin) {
      var dueAt = Ember.get(checkin, 'dueAt');
      return {
        value: checkin,
        label: (0, _moment.default)(dueAt).format('MMM DD, YYYY')
      };
    }),
    currentField: null,
    selectedCheckin: null,
    currentCheckin: Ember.computed('selectedCheckin', 'checkins.[]', function () {
      var selectedCheckin = Ember.get(this, 'selectedCheckin');

      if (!Ember.isEmpty(selectedCheckin)) {
        return selectedCheckin;
      } else {
        return Ember.get(this, "checkins.".concat(Ember.get(this, 'default')));
      }
    }),
    indexOfCurrentCheckin: Ember.computed('checkins.[]', 'currentCheckin', function () {
      return Ember.get(this, 'checkins').indexOf(Ember.get(this, 'currentCheckin'));
    }),
    reelTop: Ember.computed('imageCheckinValues.length', 'currentField', 'indexOfCurrentCheckin', function () {
      return Ember.get(this, 'indexOfCurrentCheckin') / Ember.get(this, 'imageCheckinValues.length') * -100;
    }),
    hasPreviousCheckin: Ember.computed('indexOfCurrentCheckin', function () {
      return Ember.get(this, 'indexOfCurrentCheckin') > 0;
    }),
    hasNextCheckin: Ember.computed('imageCheckinValues.length', 'indexOfCurrentCheckin', function () {
      var index = Ember.get(this, 'indexOfCurrentCheckin');
      var length = Ember.get(this, 'checkins.length');
      return length > index + 1;
    }),
    indexDots: Ember.computed('imageCheckinValues.[]', function () {
      return new Array(Ember.get(this, 'imageCheckinValues.length'));
    }),
    actions: {
      previousCheckin: function previousCheckin() {
        if (!Ember.get(this, 'hasPreviousCheckin')) {
          return false;
        }

        this._sequenceCheckin(-1);
      },
      nextCheckin: function nextCheckin() {
        if (!Ember.get(this, 'hasNextCheckin')) {
          return false;
        }

        this._sequenceCheckin(1);
      },
      isCurrentIndex: function isCurrentIndex(i, currentIndex) {
        return i == currentIndex;
      }
    },
    _sequenceCheckin: function _sequenceCheckin(indexSeq) {
      var move = indexSeq > 0 ? 1 : -1;
      var newIndex = Ember.get(this, 'indexOfCurrentCheckin') + move;
      var newCheckin = Ember.get(this, 'checkins').objectAt(newIndex);
      this.set('selectedCheckin', newCheckin);
    }
  });

  _exports.default = _default;
});