define("fitbase/components/checkin-history-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CWdbXIme",
    "block": "{\"symbols\":[\"note\",\"i\",\"number\"],\"statements\":[[7,\"tr\",true],[10,\"class\",\"checkin-history__numbers\"],[8],[4,\"if\",[[24,[\"checkin\",\"completedAt\"]]],null,{\"statements\":[[7,\"td\",true],[11,\"title\",[29,[[28,\"moment-format\",[[24,[\"checkin\",\"completedAt\"]],\"dddd - MMMM D, Y @ ha\"],null]]]],[8],[1,[28,\"moment-from-now\",[[24,[\"checkin\",\"completedAt\"]]],null],false],[9]],\"parameters\":[]},{\"statements\":[[7,\"td\",true],[11,\"title\",[29,[[28,\"moment-format\",[[24,[\"checkin\",\"dueAt\"]],\"dddd - MMMM D, Y @ ha\"],null]]]],[8],[0,\"Sent \"],[1,[28,\"moment-from-now\",[[24,[\"checkin\",\"dueAt\"]]],null],false],[9]],\"parameters\":[]}],[4,\"if\",[[24,[\"checkin\",\"completedAt\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"checkinNumbers\"]]],null,{\"statements\":[[7,\"td\",true],[8],[1,[23,3,[\"value\"]],false],[9]],\"parameters\":[3]},{\"statements\":[[7,\"td\",true],[10,\"colspan\",\"100%\"],[8],[0,\"...\"],[9]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[7,\"td\",true],[10,\"colspan\",\"100%\"],[8],[0,\"Incompleted...\"],[9]],\"parameters\":[]}],[9],[4,\"if\",[[24,[\"checkinNotes\"]]],null,{\"statements\":[[7,\"tr\",true],[10,\"class\",\"checkin-history__notes\"],[8],[7,\"td\",true],[11,\"colspan\",[22,\"colspan\"]],[10,\"class\",\"checkin-notes\"],[8],[4,\"each\",[[24,[\"checkinNotes\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"checkin-notes__note\"],[8],[7,\"div\",true],[10,\"class\",\"checkin-notes__title\"],[8],[1,[23,1,[\"label\"]],false],[9],[7,\"div\",true],[10,\"class\",\"checkin-notes__text\"],[8],[1,[23,1,[\"value\"]],false],[9],[9]],\"parameters\":[1,2]},null],[9],[9]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/checkin-history-row/template.hbs"
    }
  });

  _exports.default = _default;
});