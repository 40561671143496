define("fitbase/coach/clients/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('client');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.setProperties({
        clients: this.modelFor('coach.clients')
      });
    },
    deactivate: function deactivate() {
      var client = this.get('currentModel');

      if (client.get('isNew')) {
        client.deleteRecord();
      }
    },
    actions: {
      save: function save() {
        var _this = this;

        var model = this.modelFor(this.routeName);
        model.save().then(function (client) {
          _this.transitionTo('coach.clients.client', client);
        });
      }
    }
  });

  _exports.default = _default;
});