define("fitbase/components/loading-dots/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cSVig1BF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"b\",true],[8],[0,\"•\"],[9],[7,\"b\",true],[8],[0,\"•\"],[9],[7,\"b\",true],[8],[0,\"•\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/loading-dots/template.hbs"
    }
  });

  _exports.default = _default;
});