define("fitbase/coach/clients/client/checkins/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('coach.clients.client.checkins');
    }
  });

  _exports.default = _default;
});