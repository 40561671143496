define("fitbase/components/scheduled-form-date-time/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.center = (0, _moment.default)();
    },
    now: (0, _moment.default)().hours(0).minute(0).seconds(0).milliseconds(0).toDate(),
    today: (0, _moment.default)().add(1, "days").hours(0).minutes(0).seconds(0).milliseconds(0).toDate(),
    nextAt: Ember.computed.alias('form.nextAt'),
    outlet: "next-date__modal",
    nextAtText: Ember.computed('form.{frequency,nextAt}', function () {
      var nextAt = this.get('form.nextAt') ? (0, _moment.default)(this.get('form.nextAt')) : 'not set';
      var frequencies = {
        "7": "weekly",
        "14": "every 2 weeks",
        "28": "every 4 weeks"
      };
      var frequency = this.get('form.frequency') ? frequencies[this.get('form.frequency')] : '...';
      return {
        date: nextAt.format('dddd, MMM Do'),
        frequency: frequency,
        time: nextAt.format('ha')
      };
    }),
    setNextAt: function setNextAt(date) {
      var now = (0, _moment.default)(new Date());
      date = (0, _moment.default)(date);

      if (_moment.default.compare(date, now) == -1) {
        this.set('intendedNextAt', date);
        this.set('showModalRequireFuture', true);
      } else {
        this.set('nextAt', date.toDate());

        if (Ember.isEmpty(this.get('form.frequency'))) {
          this.set('form.frequency', 7);
        }
      }
    },
    actions: {
      hideAll: function hideAll() {
        this.set('isShowingCalendar', false);
        this.set('isShowingTime', false);
        this.set('isShowingFrequency', false);
      },
      showCalendar: function showCalendar() {
        this.toggleProperty('isShowingCalendar');
      },
      onSelectNextAt: function onSelectNextAt(input) {
        var now = new Date();
        var isToday = _moment.default.compare((0, _moment.default)(input).startOf('day'), (0, _moment.default)(now).startOf('day')) === 0;

        if (isToday) {
          input.setHours(now.getHours() + 1);
        }

        this.setNextAt(input);
      },
      showTime: function showTime() {
        this.toggleProperty('isShowingTime');
      },
      onSelectHour: function onSelectHour(hour) {
        var newDate = new Date(this.nextAt);
        newDate.setHours(hour);
        this.setNextAt(newDate);
      },
      showFrequency: function showFrequency() {
        this.toggleProperty('isShowingFrequency');
      },
      clearSchedule: function clearSchedule() {
        this.set('nextAt', null);
      }
    }
  });

  _exports.default = _default;
});