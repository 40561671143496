define("fitbase/components/checkin-field-number/component", ["exports", "ember-gestures/mixins/recognizers"], function (_exports, _recognizers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_recognizers.default, {
    tagName: ''
  });

  _exports.default = _default;
});