define("fitbase/coach/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2YIm+10I",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"feature-nav\"],[8],[7,\"div\",true],[10,\"class\",\"feature-nav__logo\"],[8],[1,[28,\"inline-svg\",[\"fitspace-logo-on-dark\"],null],false],[9],[7,\"div\",true],[10,\"class\",\"feature-nav__links\"],[8],[4,\"link-to\",null,[[\"class\",\"route\"],[\"feature-link\",\"coach.clients\"]],{\"statements\":[[1,[28,\"inline-svg\",[\"assets/images/icon-people-on-dark\"],[[\"class\"],[\"feature-link__icon svg-icon\"]]],false],[7,\"div\",true],[10,\"class\",\"feature-link__text\"],[8],[0,\"Clients\"],[9]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"route\"],[\"feature-link\",\"coach.settings\"]],{\"statements\":[[1,[28,\"inline-svg\",[\"assets/images/icon-cog\"],null],false],[7,\"div\",true],[10,\"class\",\"feature-link__text\"],[8],[0,\"Settings\"],[9]],\"parameters\":[]},null],[9],[9],[7,\"div\",true],[10,\"class\",\"main\"],[8],[1,[28,\"header-nav/trainer\",null,[[\"doLogout\"],[[28,\"action\",[[23,0,[]],[28,\"route-action\",[\"logout\"],null]],null]]]],false],[1,[22,\"outlet\"],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/coach/template.hbs"
    }
  });

  _exports.default = _default;
});