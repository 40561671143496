define("fitbase/components/textarea-one-way/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'textarea',
    attributeBindings: ['value', 'placeholder', 'data-stripe', 'name', 'disabled'],
    classNames: ["input__field--textarea"],
    classNameBindings: ['autoresize:input__field--autoresize'],
    _sanitizedValue: undefined,
    autoresize: false,
    minRows: 1,
    touchMoved: false,
    input: function input() {
      this._handleChangeEvent();
    },
    change: function change() {
      this._handleChangeEvent();
    },
    didUpdate: function didUpdate() {
      this._handleChangeEvent();
    },
    // This prevents the focusing of the
    // textarea when swiping to scroll a device
    touchStart: function touchStart() {
      this.set('touchMoved', false);
    },
    touchMove: function touchMove() {
      this.set('touchMoved', true);
    },
    touchEnd: function touchEnd(e) {
      if (this.get('touchMoved')) {
        e.stopPropagation();
      }
    },
    _handleChangeEvent: function _handleChangeEvent() {
      var value = this.readDOMAttr('value');

      this._processNewValue.call(this, value);

      this._setRows();
    },
    _processNewValue: function _processNewValue(rawValue) {
      if (typeof rawValue == "undefined") {
        rawValue = '';
      }

      var value = this.sanitizeInput(rawValue);

      if (this._sanitizedValue !== value) {
        this._sanitizedValue = value;
        this.get('update')(value);
      }
    },
    _initResize: function _initResize() {
      if (!this.get('autoresize')) {
        return false;
      }

      var value = Ember.get(this, 'value');
      var elem = this.element;
      var minRows = Ember.get(this, 'minRows');
      var baseScrollHeight;
      elem.setAttribute('rows', minRows);
      elem.value = '';
      baseScrollHeight = elem.scrollHeight;
      elem.value = value;
      Ember.set(this, 'lineHeight', window.getComputedStyle(elem).lineHeight);
      Ember.set(this, 'baseScrollHeight', baseScrollHeight);
    },
    _setRows: function _setRows() {
      if (!this.get('autoresize')) {
        return false;
      }

      var elem = this.element;
      var lineHeight = parseInt(window.getComputedStyle(elem).lineHeight);
      var baseScrollHeight = Ember.get(this, 'baseScrollHeight');
      var minRows = Ember.get(this, 'minRows');
      elem.rows = minRows;
      var scrollHeight = elem.scrollHeight;
      var rows = minRows + Math.ceil((scrollHeight - baseScrollHeight) / lineHeight);
      elem.rows = rows;
    },
    sanitizeInput: function sanitizeInput(input) {
      return input;
    },
    didRender: function didRender() {
      var _this = this;

      if (this.get('autoresize')) {
        if (!Ember.get(this, 'baseScrollHeight')) {
          Ember.run.next(function () {
            _this._initResize();

            _this.element.classList.remove('loading');

            _this._setRows();
          });
        }
      }
    },
    didInsertElement: function didInsertElement() {
      if (!this.get('update')) {
        throw new Error("You must provide an `update` action to `{{".concat(this.templateName, "}}`."));
      }

      this._processNewValue.call(this, this.get('value'));
    }
  });

  _exports.default = _default;
});