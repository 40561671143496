define("fitbase/components/checkin-history-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uRrucs1s",
    "block": "{\"symbols\":[\"checkin\",\"field\"],\"statements\":[[7,\"thead\",true],[8],[7,\"tr\",true],[8],[7,\"th\",true],[11,\"style\",[22,\"colwidth\"]],[8],[0,\"Date\"],[9],[4,\"each\",[[24,[\"numberFields\"]]],null,{\"statements\":[[7,\"th\",true],[11,\"style\",[22,\"colwidth\"]],[8],[1,[23,2,[\"text\"]],false],[9]],\"parameters\":[2]},{\"statements\":[[4,\"if\",[[24,[\"hasNoteFields\"]]],null,{\"statements\":[[7,\"th\",true],[10,\"style\",\"text-align: right\"],[8],[0,\"Click row to see notes\"],[9]],\"parameters\":[]},{\"statements\":[[7,\"th\",true],[8],[0,\"no notes\"],[9]],\"parameters\":[]}]],\"parameters\":[]}],[9],[9],[4,\"each\",[[24,[\"checkins\"]]],null,{\"statements\":[[1,[28,\"checkin-history-row\",null,[[\"colspan\",\"checkin\",\"checkinFieldValues\"],[[24,[\"colspan\"]],[23,1,[]],[23,1,[\"checkinFieldValues\"]]]]],false]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/checkin-history-table/template.hbs"
    }
  });

  _exports.default = _default;
});