define("fitbase/components/client-head/component", ["exports", "ember-click-outside/mixin", "moment"], function (_exports, _mixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mixin.default, {
    tagName: 'header',
    classNames: ['client-head', 'header--first'],
    isShowingActions: false,
    stateClass: Ember.computed('client.{state,isEnded}', function () {
      var css = this.get('client.state');

      if (this.get('client.isEnded')) {
        css = css + " " + "ended";
      }

      return css;
    }),
    status: Ember.computed('client.{state,checkinForm}', function () {
      var updatedAt = this.get('client.stateUpdatedAt');
      var now = (0, _moment.default)();
      var updatedRelative = (0, _moment.default)(updatedAt).from(now);

      switch (Ember.get(this, 'client.state')) {
        case 'new':
          return 'Needs their check-in form set up';

        case 'checked_in':
          return 'Recently checked in';

        case 'open_checkin':
          return 'Delivered email & waiting for them to check in';

        case 'enqueued':
          return "Scheduled to get an email to check-in";

        case 'replied':
          return 'Received your reply';

        case 'paused':
          return 'Not scheduled to check-in';

        case 'late':
          return 'Late checking-in';

        case 'expired':
          return '';

        case 'archived':
          return 'Archived';

        case 'ended':
          return "End date was reached ".concat(updatedRelative);

        default:
          return '';
      }
    }),
    actions: {
      toggleActions: function toggleActions() {
        this.toggleProperty('isShowingActions');
      },
      didClickAction: function didClickAction() {
        this.set('isShowingActions', false);
      }
    },
    click: function click() {
      this.set('isShowingActions', false);
    },
    clickOutside: function clickOutside() {
      this.set('isShowingActions', false);
    },
    didInsertElement: function didInsertElement() {
      Ember.run.next(this, this.addClickOutsideListener);
    },
    willDestroyElement: function willDestroyElement() {
      this.removeClickOutsideListener();
    }
  });

  _exports.default = _default;
});