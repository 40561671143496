define("fitbase/coach/clients/client/edit/index/controller", ["exports", "fitbase/utils/client-validations", "ember-cp-validations"], function (_exports, _clientValidations, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.name': _clientValidations.fullname,
    'model.email': _clientValidations.email
  });

  var _default = Ember.Controller.extend(Validations, {
    confirmingArchive: false,
    isArchived: Ember.computed.equal('model.state', 'archived'),
    showValidations: false,
    actions: {
      promptArchive: function promptArchive() {
        this.set('confirmingArchive', true);
      },
      save: function save() {
        if (this.get('validations.isValid')) {
          return true;
        } else {
          this.set('showValidations', true);
        }
      }
    }
  });

  _exports.default = _default;
});