define("fitbase/coach/clients/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    clients: Ember.computed.alias('model'),
    activeClients: Ember.computed.filter('model.@each.{state,isNew}', function (client) {
      return client.get('state') !== "archived" && client.get('isNew') == false;
    })
  });

  _exports.default = _default;
});