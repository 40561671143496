define("fitbase/application/authenticator", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "fitbase/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    session: Ember.inject.service(),
    serverTokenEndpoint: "".concat(_environment.default.DS.host, "/").concat(_environment.default.DS.namespace, "/token"),
    serverTokenRevocationEndpoint: "".concat(_environment.default.DS.host, "/").concat(_environment.default.DS.namespace, "/revoke")
  });

  _exports.default = _default;
});