define("fitbase/utils/client-validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.email = _exports.fullname = void 0;
  var fullname = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    ignoreBlank: true,
    message: "Please enter a name"
  })];
  _exports.fullname = fullname;
  var email = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    ignoreBlank: true,
    message: "Please enter an email"
  }), (0, _emberCpValidations.validator)('format', {
    type: 'email',
    allowBlank: true,
    message: "Needs to be a valid email address"
  })];
  _exports.email = email;
  var _default = {
    fullname: fullname,
    email: email
  };
  _exports.default = _default;
});