define("fitbase/phoenix-socket/service", ["exports", "ember-phoenix/services/phoenix-socket", "fitbase/config/environment"], function (_exports, _phoenixSocket, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _phoenixSocket.default.extend({
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    init: function init() {
      var _this = this;

      // You may listen to open, "close" and "error"
      this._super.apply(this, arguments);

      this.on('open', function () {
        _this.get('flashMessages');
      });
    },
    connect: function connect()
    /*url, options*/
    {
      var jwt = this.session.get('token');
      var topic = this.session.get('subject').join(':'); // connect the socket

      this._super(_environment.default.socket.url, {
        params: {
          token: jwt
        }
      }); // join a channel


      var channel = this.joinChannel(topic, {}); // add message handlers

      channel.on("new_message", this._onNewMessage.bind(this));
    },
    _onNewMessage: function _onNewMessage(message) {
      var _console,
          _this2 = this;

      (_console = console).log.apply(_console, arguments);

      var flash = this.get('flashMessages');
      var focusedWhenDelivered = window.document.hasFocus();

      if (focusedWhenDelivered) {
        this.store.findRecord(message.model, message.model_id);
      }

      flash.newmessage(message.body, {
        title: message.title,
        sticky: !focusedWhenDelivered,
        onDestroy: function onDestroy() {
          if (!focusedWhenDelivered) {
            _this2.store.findRecord(message.model, message.model_id);
          }
        }
      });
    }
  });

  _exports.default = _default;
});