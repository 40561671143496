define("fitbase/auth/register/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model() {
      return this.store.createRecord('user');
    },
    actions: {
      doRegister: function doRegister() {
        var _this = this;

        var model = Ember.get(this, 'currentModel');
        model.save().then(function () {
          var session = Ember.get(_this, 'session');
          var email = Ember.get(model, 'email');
          var password = Ember.get(model, 'password');
          return session.authenticate('authenticator:application', email, password);
        }).catch(function () {
          console.error('There was a problem logging in the registered user');
        });
      }
    }
  });

  _exports.default = _default;
});