define("fitbase/components/field-schema-sortable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gpkaGD3g",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"field-schema__label\"],[8],[7,\"div\",true],[10,\"class\",\"field-schema__input\"],[8],[1,[22,\"label\"],false],[9],[9],[4,\"unless\",[[24,[\"isImage\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"field-schema__unit\"],[8],[7,\"div\",true],[10,\"class\",\"field-schema__unit-select field-schema__unit-select--has-value\"],[8],[7,\"div\",true],[10,\"class\",\"select\"],[8],[1,[22,\"unitLabel\"],false],[9],[9],[9]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/field-schema-sortable/template.hbs"
    }
  });

  _exports.default = _default;
});