define("fitbase/components/flash-message-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["flash-message__icon"],
    type: "info",
    svgIcon: Ember.computed('type', function () {
      var prefix = "assets/images/";

      switch (this.type) {
        case 'newmessage':
          return prefix + 'icon-email';

        default:
          return prefix + 'icon-info-mono';
      }
    })
  });

  _exports.default = _default;
});