define("fitbase/components/modal-confirm/component", ["exports", "fitbase/lib/assert-closure"], function (_exports, _assertClosure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    style: null,
    // check(green) | warn(red) | info(yellow) | attention(violet) | action(blue)
    title: 'Confirm?',
    message: null,
    cancelText: 'Cancel',
    confirmText: 'Confirm',
    onConfirm: _assertClosure.default,
    onCancel: _assertClosure.default,
    modalStyleClass: Ember.computed('style', function () {
      if (this.style) {
        return 'modal--' + this.style;
      } else {
        return '';
      }
    }),
    modalButtonClass: Ember.computed('style', function () {
      switch (this.style) {
        case 'primary':
          return 'button--primary';

        case 'attention':
          return 'button--attention';

        case 'notice':
          return 'button--notice';

        case 'action':
          return 'button--action';

        case 'subtle':
          return 'button--subtle';

        case 'default':
        default:
          return 'button--default';
      }
    }),
    actions: {
      didClickOff: function didClickOff(e) {
        var didClickOff = e.target.classList.contains('modal-wrapper');

        if (this.clickOff && didClickOff) {
          this.onCancel();
        }
      },
      cancel: function cancel() {
        this.onCancel.apply(this, arguments);
      },
      confirm: function confirm() {
        this.onConfirm.apply(this, arguments);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.later(function () {
        _this.set('activeClass', 'active');
      }, 100);
    }
  });

  _exports.default = _default;
});