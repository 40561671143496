define("fitbase/components/config-checkin-time/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this.hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      this.periods = ['am', 'pm'];

      this._super.apply(this, arguments);
    },
    nextAtTime: Ember.computed('nextAt', function () {
      return this.getTwelveFromDate(this.nextAt);
    }),
    getTwelveFromDate: function getTwelveFromDate(date) {
      var fullhour = date.getHours();
      var hour = fullhour;
      var period = 'am';

      if (fullhour > 11) {
        period = 'pm';
      }

      if (fullhour > 12) {
        hour = fullhour - 12;
      }

      if (fullhour === 0) {
        hour = 12;
        period = 'am';
      }

      return {
        hour: hour,
        period: period,
        fullHour: fullhour
      };
    },
    nextAtIsToday: Ember.computed('nextAt', function () {
      var nextAtDay = (0, _moment.default)(this.nextAt).hours(0).minutes(0).seconds(0).milliseconds(0);
      var today = (0, _moment.default)().hours(0).minutes(0).seconds(0).milliseconds(0);
      return _moment.default.compare(nextAtDay, today) === 0;
    }),
    nowPeriodTime: Ember.computed(function () {
      var now = new Date();
      return this.getTwelveFromDate(now);
    }),
    isAmEnabled: Ember.computed('nextAt', function () {
      if (this.nextAtIsToday) {
        if (this.get('nowPeriodTime.period') == "am") return true;
        if (this.get('nowPeriodTime.period') == 'pm') return false;
      }

      return true;
    }),
    nextAtHour: Ember.computed.alias('nextAtTime.hour'),
    nextAtPeriod: Ember.computed.alias('nextAtTime.period'),
    isAm: Ember.computed.equal('nextAtPeriod', 'am'),
    isPm: Ember.computed.not('isAm'),
    getFullHour: function getFullHour(hour, period) {
      var numHour = parseInt(hour, 10);

      switch ("".concat(numHour).concat(period)) {
        case '12am':
          return 0;

        case '12pm':
          return 12;

        default:
          return period == "am" ? numHour : numHour + 12;
      }
    },
    actions: {
      selectHour: function selectHour(hour) {
        var fullHour = this.getFullHour(hour, this.nextAtPeriod);
        this.onSelectHour(fullHour);
      },
      selectPeriod: function selectPeriod(period) {
        var fullHour = this.getFullHour(this.nextAtHour, period);
        this.onSelectHour(fullHour);
      }
    }
  });

  _exports.default = _default;
});