define("fitbase/coach/settings/profile/controller", ["exports", "fitbase/utils/user-validations", "ember-cp-validations"], function (_exports, _userValidations, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.name': _userValidations.fullname,
    'model.email': _userValidations.email
  });

  var _default = Ember.Controller.extend(Validations, {
    showValidations: false,
    hasChanges: Ember.computed('model.{hasBufferedChanges,hasDirtyAttributes}', function () {
      return this.get('model.hasBufferedChanges') || this.get('model.hasDirtyAttributes');
    }),
    namePlaceholder: Ember.computed('model.name', function () {
      if (this.get('model.name')) {
        return "Client emails will show from \"".concat(this.get('model.name'), " via Fitspace Pro\"");
      } else {
        return 'Client emails will show from "<Name> via Fitspace Pro"';
      }
    }),
    actions: {
      save: function save() {
        if (this.get('validations.isValid')) {
          return true;
        } else {
          this.set('showValidations', true);
        }
      }
    }
  });

  _exports.default = _default;
});