define("fitbase/components/checkin-confirm/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNames: ['checkin-confirm'],
    subject: Ember.computed.alias('session.subjectType'),
    isUser: Ember.computed.equal('subject', 'user'),
    completedAndSaved: Ember.computed('subject', 'checkin.completedAndSaved', function () {
      if (Ember.get(this, 'subject') === "user") {
        return false;
      } else {
        return Ember.get(this, 'checkin.completedAndSaved');
      }
    }),
    actions: {
      goToField: function goToField(checkinField) {
        Ember.get(this, 'goToField')(checkinField);
      },
      submit: function submit() {
        Ember.get(this, 'submit')();
      }
    }
  });

  _exports.default = _default;
});