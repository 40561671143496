define("fitbase/components/checkin-history-table/component", ["exports", "fitbase/utils/field-units"], function (_exports, _fieldUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var unitFields = _fieldUnits.default.lookupList('inputType');

  var _default = Ember.Component.extend({
    tagName: 'table',
    classNames: 'checkin-history__table',
    fieldsSorted: Ember.computed('fields.[]', function () {
      if (Ember.get(this, 'fields')) {
        return Ember.get(this, 'fields').sortBy('sequence', 'id');
      } else {
        return Ember.get(this, 'fields');
      }
    }),
    numberFields: Ember.computed.filter('fieldsSorted.[]', function (f) {
      return unitFields[Ember.get(f, 'unit')] === "number";
    }),
    hasNoteFields: Ember.computed('fields.[]', function () {
      if (Ember.get(this, 'fields')) {
        return Ember.get(this, 'fields').any(function (item) {
          return ["note", "text"].indexOf(unitFields[Ember.get(item, 'unit')]) >= 0;
        });
      } else {
        return false;
      }
    }),
    colspan: Ember.computed('numberfields.[]', function () {
      var numFieldLen = Ember.get(this, 'numberFields.length');
      var colCount = numFieldLen ? numFieldLen + 1 : 2;
      return Ember.String.htmlSafe(colCount);
    }),
    colwidth: Ember.computed('colspan', function () {
      return Ember.String.htmlSafe("width: ".concat(100 / Ember.get(this, 'colspan'), "%"));
    })
  });

  _exports.default = _default;
});