define("fitbase/coach/clients/client/checkins-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jSVGi1/z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"columns\"],[8],[7,\"div\",true],[10,\"class\",\"column column--center client\"],[8],[7,\"section\",true],[8],[1,[28,\"client-head\",null,[[\"client\"],[[24,[\"client\"]]]]],false],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"center\",true],[8],[7,\"div\",true],[10,\"class\",\"h2\"],[8],[0,\"Loading history\"],[9],[7,\"br\",true],[8],[9],[1,[28,\"loading-dots\",null,[[\"classNames\"],[\"big\"]]],false],[9],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/coach/clients/client/checkins-loading/template.hbs"
    }
  });

  _exports.default = _default;
});