define("fitbase/components/header-nav/trainer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'header',
    classNames: ['header'],
    classNameBindings: ['isLight:light'],
    currentUser: Ember.computed.alias('session.currentUser'),
    email: Ember.computed.alias('session.currentUser.email'),
    isLight: true,
    session: Ember.inject.service(),
    intercom: Ember.inject.service(),
    doLogout: function doLogout() {},
    didInsertElement: function didInsertElement() {
      this.get("intercom").stop();
    },
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      var intercom = this.get('intercom');
      this.get('session.currentUser').then(function (user) {
        var name = user.get('name'),
            email = user.get('email'),
            hash = _this.get('session.claims.ic'),
            user_id = _this.get('session.claims.sub');

        intercom.set('user.name', name);
        intercom.set('user.email', email);
        intercom.set('user.user_hash', hash);
        intercom.set('user.user_id', user_id);
        var attrs = {
          email: email,
          name: name,
          user_id: user_id,
          user_hash: hash
        };
        intercom.update(attrs);
        intercom.start();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.get('intercom').stop();
    },
    actions: {
      logout: function logout() {
        Ember.get(this, 'doLogout')();
      }
    }
  });

  _exports.default = _default;
});