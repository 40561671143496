define("fitbase/router", ["exports", "fitbase/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    intercom: Ember.inject.service(),
    session: Ember.inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this._trackPage();

      this._classifyPage();

      this._scrollTop();
    },
    _scrollTop: function _scrollTop() {
      if (!this.get('noResetScroll')) {
        window.scrollTo(0, 0);
      }
    },
    _trackPage: function _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, this._trackPageAfterRender);
    },
    _trackPageAfterRender: function _trackPageAfterRender() {
      this.get('intercom').update();
    },
    _classifyPage: function _classifyPage() {
      Ember.run.scheduleOnce('afterRender', this, this._classifyPageAfterRender);
    },
    _classifyPageAfterRender: function _classifyPageAfterRender() {
      var path = this.get('url').replace(/\//g, '_').replace('_', '');
      var route = this.getWithDefault('currentRouteName', 'unknown');
      var el = document.querySelector('body > .ember-view');

      if (el) {
        el.setAttribute('route', route);
        el.setAttribute('path', path);
      }
    }
  });
  Router.map(function () {
    this.route('auth.register', {
      path: 'signup'
    });
    this.route('auth.login', {
      path: 'login'
    });
    this.route('auth.logout', {
      path: 'logout'
    });
    this.route('coach', function () {
      this.route('settings', function () {
        this.route('profile');
        this.route('security');
      });
      this.route('clients', function () {
        this.route('new');
        this.route('client', {
          path: ':id'
        }, function () {
          this.route('checkins', function () {
            this.route('pictures');
          });
          this.route('edit', function () {
            this.route('checkin-form', function () {
              this.route('create-template');
              this.route('import');
            });
          });
        });
      });
    });
    this.route('checkin-link', {
      path: 'checkin-link/:id/:link_id'
    });
    this.route('checkin', {
      path: 'checkin/:id'
    });
    this.route('not-found', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});