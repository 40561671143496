define("fitbase/components/checkin-confirm-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['checkin-confirm__field-value'],
    classNameBindings: ['checkinField.unit', 'isEmpty'],
    imgixQuery: "?fit=crop&w=340&h=340",
    isImage: Ember.computed.equal('checkinField.unit', 'image'),
    isEmpty: Ember.computed('checkinField.checkinValue.value', function () {
      return Ember.isEmpty(Ember.get(this, 'checkinField.checkinValue.value'));
    }),
    click: function click() {
      Ember.get(this, 'goToField')(Ember.get(this, 'checkinField'));
    }
  });

  _exports.default = _default;
});