define("fitbase/checkin-field-value/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    label: attr('string'),
    value: attr('string'),
    unit: attr('string'),
    delta: attr('delta'),
    inputType: attr('string'),
    sequence: attr('number'),
    createdAt: attr('string'),
    checkin: belongsTo('checkin'),
    deltaSign: Ember.computed('delta', function () {
      if (this.get('delta') < 0) {
        return 'is-negative';
      }

      if (this.get('delta') > 0) {
        return 'is-positive';
      }

      return 'is-neutral';
    })
  });

  _exports.default = _default;
});