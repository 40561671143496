define("fitbase/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dGSuN1uc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"error-page error-page--404\"],[8],[7,\"div\",true],[10,\"class\",\"error-page__content\"],[8],[7,\"div\",true],[10,\"class\",\"error-page__title\"],[8],[0,\"404\"],[9],[7,\"div\",true],[10,\"class\",\"error-page__message\"],[8],[0,\"Looks like something is missing!\"],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/not-found/template.hbs"
    }
  });

  _exports.default = _default;
});