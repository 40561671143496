define("fitbase/components/checkin-field/component", ["exports", "ember-gestures/mixins/recognizers", "fitbase/utils/field-units"], function (_exports, _recognizers, _fieldUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_recognizers.default, {
    recognizers: 'tap',
    tagName: 'li',
    classNames: ['checkin-input'],
    classNameBindings: ['isPrev:checkin-input--prev', 'isCurrent:checkin-input--current', 'isNext:checkin-input--next'],
    schema: Ember.computed.alias('checkinField'),
    inputName: Ember.computed('checkinField.id', function () {
      return "field-".concat(Ember.get(this, 'checkinField.id'));
    }),
    checkinValue: Ember.computed.alias('checkinField.checkinValue'),
    value: Ember.computed.alias('checkinValue.value'),
    bufferValue: undefined,
    label: Ember.computed.alias('schema.text'),
    unit: Ember.computed('schema.unit', function () {
      var unit = Ember.get(this, 'schema.unit');
      return Ember.get(this, 'fieldUnitSuffixes')[unit];
    }),
    fieldUnitSuffixes: _fieldUnits.default.lookupList('suffix'),
    fieldInputTypes: _fieldUnits.default.lookupList('inputType'),
    inputType: Ember.computed('schema.unit', function () {
      return this.get('fieldInputTypes')[this.get('schema.unit')];
    }),
    isNumber: Ember.computed.equal('inputType', 'number'),
    isText: Ember.computed.equal('inputType', 'text'),
    isTextarea: Ember.computed.equal('inputType', 'note'),
    isFeeling: Ember.computed.equal('inputType', 'feeling'),
    isImage: Ember.computed.equal('inputType', 'image'),
    isPrev: Ember.computed('fieldIndex', 'prevFieldIndex', function () {
      return this.get('fieldIndex') === this.get('prevFieldIndex');
    }),
    isCurrent: Ember.computed('fieldIndex', 'currentFieldIndex', function () {
      return this.get('fieldIndex') === this.get('currentFieldIndex');
    }),
    isNext: Ember.computed('fieldIndex', 'nextFieldIndex', function () {
      return this.get('fieldIndex') === this.get('nextFieldIndex');
    }),
    isDisabled: Ember.computed.not('isCurrent'),
    hasPrev: Ember.computed('prevFieldIndex', function () {
      return this.get('prevFieldIndex') !== false;
    }),
    hasNext: Ember.computed('nextFieldIndex', function () {
      return this.get('nextFieldIndex') !== false;
    }),
    actions: {
      next: function next() {
        this._save();

        this.get('onNext')();

        this._blur();
      },
      prev: function prev() {
        this._save();

        this.get('onPrev')();

        this._blur();
      },
      save: function save() {
        return this._save();
      },
      update: function update(newValue) {
        Ember.set(this, 'bufferValue', newValue);

        if (this.get('checkinValue.content')) {
          Ember.set(this, 'value', newValue);
        }
      },
      showSummary: function showSummary() {
        var _this = this;

        this._save().then(function () {
          _this.get('showSummary')();
        });
      }
    },
    // _private methods
    _save: function _save() {
      var checkinValue = this.get('checkinValue'),
          bufferValue = this.get('bufferValue'),
          checkinField = this.get('checkinField');
      return this.get('save')(checkinValue, bufferValue, checkinField);
    },
    _focus: function _focus() {
      this.element.querySelector('.checkin-input__elem').focus();
    },
    _blur: function _blur() {
      var focused = this.element.querySelector(':focus');

      if (focused != null) {
        focused.blur();
      }
    }
  });

  _exports.default = _default;
});