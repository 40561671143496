define("fitbase/checkin/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    currentIndexView: attr('number'),
    dueAt: attr('date'),
    completedAt: attr('date'),
    updatedAt: attr('date'),
    token: attr('string'),
    // relationships
    client: belongsTo('client', {
      inverse: 'checkins'
    }),
    checkinForm: belongsTo('checkinForm'),
    checkinFieldValues: hasMany('checkin-field-value'),
    checkinValues: hasMany('checkin-value'),
    checkinReplies: hasMany('checkin-reply'),
    checkinReplyDraft: Ember.computed('checkinReplies.@each.sentAt', function () {
      return Ember.get(this, 'checkinReplies').findBy('sentAt', null);
    }),
    completedAndSaved: Ember.computed('completedAt', 'hasDirtyAttributes', function () {
      var completed = Ember.get(this, 'completedAt');
      var dirty = Ember.get(this, 'hasDirtyAttributes');
      return completed && !dirty;
    })
  });

  _exports.default = _default;
});