define("fitbase/components/checkin-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TsIMbqT7",
    "block": "{\"symbols\":[\"checkinField\",\"index\"],\"statements\":[[4,\"if\",[[24,[\"showingSummary\"]]],null,{\"statements\":[[1,[28,\"checkin-confirm\",null,[[\"checkin\",\"checkinFields\",\"submit\",\"isFilled\",\"goToField\"],[[24,[\"checkin\"]],[24,[\"checkinFields\"]],[24,[\"submit\"]],[24,[\"isFilled\"]],[28,\"action\",[[23,0,[]],\"goToField\"],null]]]],false]],\"parameters\":[]},{\"statements\":[[7,\"ol\",true],[10,\"class\",\"checkin-form__fields\"],[8],[4,\"each\",[[24,[\"checkinFields\"]]],null,{\"statements\":[[1,[28,\"checkin-field\",null,[[\"checkinId\",\"checkinField\",\"fieldIndex\",\"currentFieldIndex\",\"nextFieldIndex\",\"prevFieldIndex\",\"showSummary\",\"save\",\"onNext\",\"onPrev\"],[[24,[\"checkin\",\"id\"]],[23,1,[]],[23,2,[]],[24,[\"currentFieldIndex\"]],[24,[\"nextFieldIndex\"]],[24,[\"prevFieldIndex\"]],[24,[\"showSummary\"]],[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"next\"],null],[28,\"action\",[[23,0,[]],\"prev\"],null]]]],false]],\"parameters\":[1,2]},{\"statements\":[[0,\"No checkin has been set up for you yet! Check with your trainer.\"]],\"parameters\":[]}],[9]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/checkin-form/template.hbs"
    }
  });

  _exports.default = _default;
});