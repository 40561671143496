define("fitbase/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DuGued4k",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"header-nav/trainer\",null,[[\"doLogout\"],[[28,\"action\",[[23,0,[]],[28,\"route-action\",[\"logout\"],null]],null]]]],false],[7,\"div\",true],[10,\"class\",\"guide-todos\"],[8],[7,\"div\",true],[10,\"class\",\"guide__intro\"],[8],[7,\"div\",true],[10,\"class\",\"h1\"],[8],[0,\"Hey, you're new!\"],[9],[7,\"div\",true],[10,\"class\",\"p\"],[8],[0,\"To help you get started we've made a list of things to do that will get you set up.\"],[9],[9],[4,\"link-to\",null,[[\"class\",\"route\"],[\"guide-todo button button--action\",\"clients\"]],{\"statements\":[[0,\"Go to clients\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"guide-todo guide-todo--disabled button button--subtle\"],[8],[0,\" Set up a check-in\"],[9],[4,\"link-to\",null,[[\"class\",\"route\"],[\"guide-todo guide-todo--disabled button button--subtle\",\"clients\"]],{\"statements\":[[0,\"Respond to a check-in\"]],\"parameters\":[]},null],[9],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/index/template.hbs"
    }
  });

  _exports.default = _default;
});