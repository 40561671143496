define("fitbase/coach/clients/client/checkins/pictures/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uPKe4x6W",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"column column--center client\"],[8],[7,\"section\",true],[8],[1,[28,\"client-head\",null,[[\"client\"],[[24,[\"model\",\"client\"]]]]],false],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"link link--strong\",\"coach.clients.client\",[24,[\"model\",\"client\",\"id\"]]]],{\"statements\":[[0,\"Back to overview\"]],\"parameters\":[]},null],[9],[7,\"section\",true],[8],[7,\"div\",true],[10,\"class\",\"pics-history\"],[8],[7,\"div\",true],[10,\"class\",\"pics-history__title\"],[8],[7,\"div\",true],[10,\"class\",\"h2\"],[8],[0,\"Pictures\"],[9],[9],[7,\"div\",true],[10,\"class\",\"pics-fields\"],[8],[7,\"div\",true],[10,\"class\",\"pics-fields__icon\"],[8],[1,[28,\"inline-svg\",[\"assets/images/down-arrow\"],null],false],[9],[7,\"div\",true],[10,\"class\",\"pics-fields__selected\"],[8],[1,[28,\"one-way-select\",null,[[\"value\",\"options\",\"optionLabelPath\",\"optionTargetPath\",\"update\",\"name\"],[[24,[\"currentField\"]],[24,[\"fieldOptions\"]],\"label\",\"value\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selectedField\"]]],null]],null],\"field\"]]],false],[9],[9],[7,\"div\",true],[10,\"class\",\"pics-comparator\"],[8],[1,[28,\"pics-column\",null,[[\"currentField\",\"checkins\",\"imageCheckinValues\",\"default\",\"class\"],[[24,[\"currentField\"]],[24,[\"checkins\"]],[24,[\"imageCheckinValues\"]],\"firstObject\",\"pics-column--before\"]]],false],[1,[28,\"pics-column\",null,[[\"currentField\",\"checkins\",\"imageCheckinValues\",\"default\",\"class\"],[[24,[\"currentField\"]],[24,[\"checkins\"]],[24,[\"imageCheckinValues\"]],\"lastObject\",\"pics-column--after\"]]],false],[9],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/coach/clients/client/checkins/pictures/template.hbs"
    }
  });

  _exports.default = _default;
});