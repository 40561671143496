define("fitbase/components/client-li/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ubej9OIX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"client-li__a\",\"coach.clients.client\",[24,[\"client\",\"id\"]]]],{\"statements\":[[7,\"div\",true],[10,\"data-initials\",\"as\"],[10,\"class\",\"client-li__avatar\"],[8],[7,\"img\",true],[11,\"src\",[24,[\"client\",\"avatarUrl\"]]],[8],[9],[9],[7,\"div\",true],[10,\"class\",\"client-li__name\"],[8],[1,[28,\"text-highlight\",[[24,[\"client\",\"name\"]]],[[\"query\"],[[24,[\"query\"]]]]],false],[9],[7,\"div\",true],[10,\"class\",\"client-li__status\"],[8],[1,[22,\"statusMessage\"],false],[9],[7,\"div\",true],[10,\"class\",\"client-li__next_at\"],[8],[1,[22,\"nextAtMessage\"],false],[9]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/client-li/template.hbs"
    }
  });

  _exports.default = _default;
});