define("fitbase/components/auth-registration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FQsaXRhG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"input-auth\",null,[[\"type\",\"value\",\"label\",\"inputName\",\"placeholder\",\"errors\",\"error\",\"enter\"],[\"email\",[24,[\"model\",\"email\"]],\"Email\",\"signup-email\",\"magnus@trainerpro.com\",[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"model.email\"],null],\"messages\"],null],[24,[\"model\",\"errors\",\"email\",\"firstObject\",\"message\"]],[24,[\"doRegister\"]]]]],false],[1,[28,\"input-auth\",null,[[\"type\",\"value\",\"label\",\"inputName\",\"placeholder\",\"errors\",\"error\",\"enter\"],[\"password\",[24,[\"model\",\"password\"]],\"Password\",\"signup-password\",\"At least 10 characters\",[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"model.password\"],null],\"messages\"],null],[24,[\"model\",\"errors\",\"password\",\"firstObject\",\"message\"]],[24,[\"doRegister\"]]]]],false],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"div\",true],[11,\"onclick\",[22,\"doRegister\"]],[11,\"class\",[29,[\"button button--med button--\",[28,\"if\",[[24,[\"buttonDisabled\"]],\"attention\",\"action\"],null]]]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"Next\"],[9],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/auth-registration/template.hbs"
    }
  });

  _exports.default = _default;
});