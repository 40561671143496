define("fitbase/application/adapter", ["exports", "ember-data/adapters/json-api", "ember-simple-auth/mixins/data-adapter-mixin", "fitbase/config/environment", "ember-data-url-templates"], function (_exports, _jsonApi, _dataAdapterMixin, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var urlSegments = {
    namespace: function namespace() {
      return _environment.default.DS.namespace;
    }
  };

  var _default = _jsonApi.default.extend(_emberDataUrlTemplates.default, _dataAdapterMixin.default, {
    host: _environment.default.DS.host,
    urlTemplate: '{+host}/{+namespace}/{pathForType}{/id}{?query*}',
    urlSegments: urlSegments,
    headers: Ember.computed('sessions.data.authenticated.access_token', function () {
      var headers = {};

      if (this.get('session.isAuthenticated')) {
        headers['Authorization'] = "Bearer ".concat(this.get('session.data.authenticated.access_token'));
      }

      return headers;
    }),
    handleResponse: function handleResponse(status, headers, payload) {
      if (Ember.get(this, 'session.isAuthenticated') && payload) {
        var error_status = Ember.get(payload, 'errors.0.status');

        if (status === 401 || error_status === 401) {
          Ember.get(this, 'session').invalidate();
        }
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});