define("fitbase/auth/login/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    email: Ember.computed.alias('model.email'),
    password: Ember.computed.alias('model.password'),
    error: Ember.computed.alias('model.error'),
    session: Ember.inject.service(),
    actions: {
      doLogin: function doLogin() {
        var _this = this;

        this.error = '';
        Ember.get(this, 'session').authenticate('authenticator:application', this.email, this.password).then(function () {}).catch(function () {
          _this.error = 'Login failed. Try otra vez!';
        });
      },
      clearError: function clearError() {
        this.error = null;
      }
    }
  });

  _exports.default = _default;
});