define("fitbase/coach/clients/client/checkins/pictures/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('coach.clients.client.checkins');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.setProperties({
        clients: this.modelFor('coach.clients')
      });
    },
    deactivate: function deactivate() {
      this.controller.set('selectedField', null);
    }
  });

  _exports.default = _default;
});