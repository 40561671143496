define("fitbase/transforms/delta", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return JSON.parse(serialized);
    },
    serialize: function serialize(deserialized) {
      if (deserialized && typeof deserialized !== "string") {
        return JSON.stringify(deserialized);
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});