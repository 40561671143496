define("fitbase/components/checkin-latest-pics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/1AmFDat",
    "block": "{\"symbols\":[\"pic\",\"i\"],\"statements\":[[7,\"div\",true],[11,\"style\",[22,\"shiftLeft\"]],[10,\"class\",\"client-pics__reel\"],[8],[4,\"each\",[[24,[\"checkinPictures\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"client-pics__set\"],[8],[4,\"if\",[[23,1,[\"delta\",\"url\"]]],null,{\"statements\":[[7,\"div\",true],[11,\"style\",[29,[\"background-image: url(\",[23,1,[\"delta\",\"url\"]],[22,\"imgixQuery\"],\")\"]]],[11,\"data-date\",[29,[[28,\"moment-format\",[[23,1,[\"delta\",\"date\"]],\"MMM D @ hA\"],null]]]],[10,\"class\",\"client-pics__pic client-pics__pic--before\"],[8],[9]],\"parameters\":[]},{\"statements\":[[7,\"div\",true],[10,\"class\",\"client-pics__pic client-pics__pic--before client-pics__pic--empty\"],[8],[9]],\"parameters\":[]}],[4,\"if\",[[23,1,[\"value\"]]],null,{\"statements\":[[7,\"div\",true],[11,\"style\",[29,[\"background-image: url(\",[23,1,[\"value\"]],[22,\"imgixQuery\"],\")\"]]],[11,\"data-date\",[29,[[28,\"moment-format\",[[24,[\"checkin\",\"dueAt\"]],\"MMM D @ hA\"],null]]]],[10,\"class\",\"client-pics__pic client-pics__pic--after\"],[8],[9]],\"parameters\":[]},{\"statements\":[[7,\"div\",true],[10,\"class\",\"client-pics__pic client-pics__pic--after client-pics__pic--empty\"],[8],[9]],\"parameters\":[]}],[9]],\"parameters\":[1,2]},null],[9],[4,\"unless\",[[24,[\"isFirstPicSet\"]]],null,{\"statements\":[[7,\"div\",false],[12,\"class\",\"client-pics__prev\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"prevPic\"]],[8],[0,\"‹\"],[9]],\"parameters\":[]},null],[4,\"unless\",[[24,[\"isLastPicSet\"]]],null,{\"statements\":[[7,\"div\",false],[12,\"class\",\"client-pics__next\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"nextPic\"]],[8],[0,\"›\"],[9]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/checkin-latest-pics/template.hbs"
    }
  });

  _exports.default = _default;
});