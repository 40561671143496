define("fitbase/validators/collection", ["exports", "ember-cp-validations/validators/collection"], function (_exports, _collection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _collection.default;
    }
  });
});