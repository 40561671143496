define("fitbase/components/checkin-confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "omJcYyUA",
    "block": "{\"symbols\":[\"checkinField\",\"i\"],\"statements\":[[4,\"unless\",[[24,[\"completedAndSaved\"]]],null,{\"statements\":[[7,\"p\",true],[8],[0,\"Send in your answers at the bottom of the page, after making sure you're all done.\"],[9],[7,\"br\",true],[8],[9]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"checkin-confirm__values\"],[8],[4,\"each\",[[24,[\"checkinFields\"]]],null,{\"statements\":[[1,[28,\"checkin-confirm-field\",null,[[\"goToField\",\"checkinField\"],[[28,\"action\",[[23,0,[]],\"goToField\"],null],[23,1,[]]]]],false]],\"parameters\":[1,2]},null],[9],[4,\"if\",[[24,[\"completedAndSaved\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"checkin-confirm__done-text\"],[8],[0,\"You did it! You can close this now.\"],[9]],\"parameters\":[]},{\"statements\":[[7,\"div\",false],[12,\"class\",\"checkin-confirm__button button button--primary button--big\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"submit\"]],[8],[4,\"if\",[[24,[\"isUser\"]]],null,{\"statements\":[[0,\"Save\"]],\"parameters\":[]},{\"statements\":[[0,\"Finish & Send\"]],\"parameters\":[]}],[9]],\"parameters\":[]}],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/checkin-confirm/template.hbs"
    }
  });

  _exports.default = _default;
});