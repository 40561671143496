define("fitbase/components/checkin-form-progress/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['checkin-form__progress', 'checkin-form-progress'],
    classNameBindings: ['filled'],
    percent: 0,
    progress: Ember.computed('percent', function () {
      return Ember.String.htmlSafe("".concat(parseFloat(Ember.get(this, 'percent')).toFixed(2), "%"));
    }),
    filled: Ember.computed('percent', function () {
      return Ember.get(this, 'percent') === 100 ? 'checkin-form-progress--filled' : '';
    }),
    isSummary: Ember.computed.or('checkin.{isReviewing,completedAndSaved}'),
    isVisible: Ember.computed.not('isSummary')
  });

  _exports.default = _default;
});