define("fitbase/helpers/pad-hour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.padHour = padHour;
  _exports.default = void 0;

  function padHour(params) {
    var dateInput = params[0];
    return "0".concat(dateInput).slice(-2);
  }

  var _default = Ember.Helper.helper(padHour);

  _exports.default = _default;
});