define("fitbase/components/checkin-confirm-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bxAmm+vj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"confirm-field \",[24,[\"checkinField\",\"unit\"]]]]],[8],[1,[24,[\"checkinField\",\"text\"]],false],[9],[4,\"if\",[[24,[\"isImage\"]]],null,{\"statements\":[[7,\"img\",true],[11,\"src\",[29,[[24,[\"checkinField\",\"checkinValue\",\"value\"]],[22,\"imgixQuery\"]]]],[11,\"class\",[29,[\"confirm-value \",[24,[\"checkinField\",\"unit\"]]]]],[8],[9]],\"parameters\":[]},{\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"confirm-value \",[24,[\"checkinField\",\"unit\"]]]]],[8],[1,[24,[\"checkinField\",\"checkinValue\",\"value\"]],false],[9]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/checkin-confirm-field/template.hbs"
    }
  });

  _exports.default = _default;
});